import useGroup from '@hooks/group/useGroup';
import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, BasicListApiResponse, requestSecureGet } from '@lib/api';
import { GroupItemTypes } from '@typedef/components/Group/group.types';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Group from '../Group';
import GroupAddPopupContainer from './GroupAddPopupContainer';

const GroupContainer = () => {
  const navigate = useNavigate();

  const { groupList, __updateGroupListFromHooks, __clearGroupListFromHooks } =
    useGroup();

  const { __showPopupFromHooks } = usePopup();

  const { getAccessToken } = useToken();

  const loadGroupList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<GroupItemTypes[]>
    >(apiRoute.group.getGroupList, {}, getAccessToken());

    if (config.status === 200) {
      __updateGroupListFromHooks(data.data);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [__updateGroupListFromHooks]);

  const onListItemClicked = useCallback(
    (index: number) => {
      navigate('detail', {
        state: {
          data: groupList[index],
        },
      });
    },
    [groupList, navigate],
  );

  const onAddBtnClicked = useCallback(() => {
    __showPopupFromHooks(<GroupAddPopupContainer />);
  }, []);

  useEffect(() => {
    loadGroupList();

    return () => {
      __clearGroupListFromHooks();
    };
  }, []);

  return (
    <Group
      groupList={groupList}
      onListItemClicked={onListItemClicked}
      onAddBtnClicked={onAddBtnClicked}
    />
  );
};

export default GroupContainer;
