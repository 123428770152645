import { hidePopup, showPopup } from '@store/popup/actions';
import { RootState } from '@store/rootReducer';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function usePopup() {
  const { visibility, child } = useSelector(
    (root: RootState) => root.popupReducer,
  );

  const dispatch = useDispatch();

  const __showPopupFromHooks = useCallback(
    (diff: React.ReactNode) => dispatch(showPopup(diff)),
    [dispatch],
  );

  const __hidePopupFromHooks = useCallback(
    () => dispatch(hidePopup()),
    [dispatch],
  );

  return {
    visibility,
    child,
    __showPopupFromHooks,
    __hidePopupFromHooks,
  };
}
