import React, { useCallback, useState } from 'react';
import CSelect from '../CSelect';

type Props<T> = {
  onSelected: (selected: T) => void;
  initialSelected: string;
  options: T[];
  labelSelector: (item: T) => string;
  className?: string;
};

function CSelectContainer<T>({
  onSelected,
  initialSelected,
  options,
  labelSelector,
  className,
}: Props<T>) {
  const [selected, setSelected] = useState<T | string>(initialSelected);
  const [optionVisible, setOptionVisible] = useState(false);

  const onSelectClicked = useCallback(() => {
    setOptionVisible((prev) => !prev);
  }, []);

  const onSelectItem = useCallback(
    (selected: T) => {
      setSelected(selected);
      onSelected(selected);
      setOptionVisible(false);
    },
    [onSelected],
  );

  return (
    <CSelect
      optionVisible={optionVisible}
      onSelectClicked={onSelectClicked}
      selected={
        typeof selected === 'string' ? selected : labelSelector(selected)
      }
      options={options}
      onSelectItem={onSelectItem}
      labelSelector={labelSelector}
      className={className}
    />
  );
}

export default CSelectContainer;
