import { ProgramTypes } from '@typedef/components/Program/program.types';
import { useCallback } from 'react';

export default function useGNB() {
  const setGNB = useCallback((userProgram: ProgramTypes[]) => {
    sessionStorage.setItem('@gnb', JSON.stringify(userProgram));
  }, []);

  const getGNB = useCallback(() => {
    return JSON.parse(sessionStorage.getItem('@gnb') || '[]') as ProgramTypes[];
  }, []);

  return {
    setGNB,
    getGNB,
  };
}
