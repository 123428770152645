import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

type Props<T> = {
  data: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  onEndReached?: () => void;
  threshold?: number;
};

function FlatListContainer<T>({
  data,
  renderItem,
  containerClassName,
  containerStyle,
  threshold = 1,
  onEndReached,
}: Props<T>) {
  const { ref, inView } = useInView({
    threshold,
  });

  useEffect(() => {
    if (inView && onEndReached) {
      onEndReached();
    }
  }, [inView, onEndReached]);

  return (
    <ul className={containerClassName} style={containerStyle}>
      {data.map((val, index) => {
        return (
          <li
            key={index}
            ref={
              index >= Math.floor(data.length * threshold) ? ref : undefined
            }>
            {renderItem(val, index)}
          </li>
        );
      })}
    </ul>
  );
}

export default FlatListContainer;
