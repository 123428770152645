import {
  addProgram,
  clearProgramList,
  updateProgramList,
} from '@store/programs/actions';
import { RootState } from '@store/rootReducer';
import { ProgramTypes } from '@typedef/components/Program/program.types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function usePrograms() {
  const programs = useSelector(
    (root: RootState) => root.programListReducer.list,
  );

  const dispatch = useDispatch();

  const __updateProgramListFromHooks = useCallback(
    (diff: ProgramTypes[]) => dispatch(updateProgramList(diff)),
    [dispatch],
  );

  const __clearProgramListFromHooks = useCallback(
    () => dispatch(clearProgramList()),
    [dispatch],
  );

  const __addProgramFromHooks = useCallback(
    (diff: ProgramTypes) => dispatch(addProgram(diff)),
    [dispatch],
  );

  return {
    programs,
    __updateProgramListFromHooks,
    __clearProgramListFromHooks,
    __addProgramFromHooks,
  };
}
