import { RequestItemType } from '@typedef/components/Request/request.types';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RequstDetail from '../components/RequestDetail';

const RequestDetailContainer = () => {
  const { state } = useLocation() as {
    state: {
      data: RequestItemType;
    };
  };

  const [request, setRequest] = useState(state.data);

  return <RequstDetail data={request} />;
};

export default RequestDetailContainer;
