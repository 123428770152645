import usePopup from '@hooks/popup/usePopup';
import Popup from '@routes/components/Popup';
import React, { useCallback } from 'react';

const PopupContainer = () => {
  const { visibility, child, __hidePopupFromHooks } = usePopup();

  const onBackdropClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  return visibility ? (
    <Popup onBackdropClicked={onBackdropClicked}>{child}</Popup>
  ) : null;
};

export default PopupContainer;
