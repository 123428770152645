import useQnaList from '@hooks/component/qna/useQnaList';
import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import { apiRoute, requestSecureGet } from '@lib/api';
import { QnaListMetaType, QnaType } from '@typedef/components/Qna/qna.types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Qna from '../Qna';
import QnaCreatePopupContainer from './QnaCreatePopupContainer';

const QnaContainer = () => {
  const { getAccessToken } = useToken();
  const { __showPopupFromHooks } = usePopup();
  const { qnaList, __updateQnaList, __resetQnaList } = useQnaList();

  const [currentPage, setCurrentPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const getQnaList = useCallback(async () => {
    const token = getAccessToken();
    const {
      data,
      config: { status },
    } = await requestSecureGet<{ data: QnaType[]; meta: QnaListMetaType }>(
      apiRoute.qna.getQnas +
        `?order=DESC&orderColumnName=createdAt&page=${currentPage + 1}&take=12`,
      {},
      token,
    );

    if (status !== 200) {
      console.error('getQnaList failed');
      return;
    }

    setHasNextPage(data.meta.hasNextPage);
    __updateQnaList([...qnaList, ...data.data]);
    setCurrentPage((prev) => prev + 1);
  }, [qnaList, getAccessToken, __updateQnaList, currentPage, hasNextPage]);

  const onAddQna = useCallback(async () => {
    __showPopupFromHooks(<QnaCreatePopupContainer />);
  }, [__showPopupFromHooks]);

  const onEndReached = useCallback(async () => {
    if (hasNextPage) {
      getQnaList();
    }
  }, [getQnaList, hasNextPage]);

  useEffect(() => {
    getQnaList();

    return () => {
      __resetQnaList();
    };
  }, []);

  return (
    <Qna qnaList={qnaList} onAddQna={onAddQna} onEndReached={onEndReached} />
  );
};

export default QnaContainer;
