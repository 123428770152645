import { QnaType } from '@typedef/components/Qna/qna.types';
import React from 'react';

type Props = {
  item: QnaType;
  onCardItemClicked: () => void;
};

const QnaCardItem = ({ item, onCardItemClicked }: Props) => {
  return (
    <div className='qna-card-item'>
      <div className='qna-title'>{item.title}</div>
      <div className='content'>{item.contents}</div>
      <button className='answer-button' onClick={onCardItemClicked}>
        답변하러가기
      </button>
    </div>
  );
};

export default QnaCardItem;
