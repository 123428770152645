import { resetQnaList, updateQnaList } from '@store/qna/actions';
import { RootState } from '@store/rootReducer';
import { QnaType } from '@typedef/components/Qna/qna.types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useQnaList() {
  const { list: qnaList } = useSelector(
    (rootState: RootState) => rootState.qnaReducer,
  );
  const dispatch = useDispatch();
  const __updateQnaList = useCallback(
    (diff: QnaType[]) => dispatch(updateQnaList(diff)),
    [dispatch],
  );

  const __resetQnaList = useCallback(() => dispatch(resetQnaList()), []);

  return {
    qnaList,
    __resetQnaList,
    __updateQnaList,
  };
}
