import { ProgramTypes } from '@typedef/components/Program/program.types';
import React from 'react';
import './styles/program.styles.css';

type Props = {
  programs: ProgramTypes[];
  onListItemClicked: (item: ProgramTypes) => void;
  onAddBtnClicked: () => void;
};

const Program = ({ programs, onListItemClicked, onAddBtnClicked }: Props) => {
  return (
    <div className='program-root'>
      <div className='header-section'>
        <div className='title'>프로그램 관리</div>
        <button onClick={onAddBtnClicked} className='add-btn'>
          추가하기
        </button>
      </div>
      <div className='content-section'>
        <div className='list-section'>
          <div className='header-article'>
            <span className='header-item'>프로그램 이름</span>
            <span className='header-item'>프로그램 UID</span>
            <span className='header-item'>프로그램 경로</span>
            <span className='header-item'>프로그램 설명</span>
          </div>
          <div className='body-article'>
            {programs.map((item, index) => (
              <div
                onClick={() => onListItemClicked(item)}
                key={index}
                className='list-item'>
                <span className='item-label'>{item.name}</span>
                <span className='item-label'>{item.code}</span>
                <span className='item-label'>{item.path}</span>
                <span className='item-label'>{item.remark}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
