import images from '@assets/images';
import React from 'react';
import './styles/ccheck.box.styles.css';

type Props = {
  label: string;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
};

const CCheckBox = ({ label, setChecked, checked }: Props) => {
  return (
    <div
      className='ccheckbox-root'
      onClick={() => {
        setChecked((prev) => !prev);
      }}>
      <img
        src={
          checked
            ? images.mainbanner.check_box_on
            : images.mainbanner.check_box_off
        }
      />
      <span className='label'>{label}</span>
    </div>
  );
};

export default CCheckBox;
