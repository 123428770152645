import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, requestGet, requestPost, requestSecurePost } from '@lib/api';
import { SectionItemTypes } from '@typedef/components/CalcItem/calc.item.types';
import React, { useCallback, useEffect, useState } from 'react';
import CalcItem from '../CalcItem';

const CalcItemContainer = () => {
  const { getAccessToken } = useToken();

  const [sectionList, setSectionList] = useState<SectionItemTypes[]>([]);

  const [sectionName, setSectionName] = useState('');

  const loadSectionList = useCallback(async () => {
    const { data, config } = await requestGet<SectionItemTypes[]>(
      apiRoute.calcItem.getCalcItemList,
      {},
    );

    if (config.status === 200) {
      setSectionList(data);
    } else {
      alertMessage('내용을 불러오지 못했습니다');
    }
  }, []);

  const onSectionDeleted = useCallback((data: SectionItemTypes) => {
    setSectionList((prev) => prev.filter((item) => item.id !== data.id));
  }, []);

  const onSectionAddClicked = useCallback(async () => {
    const { data, config } = await requestSecurePost<SectionItemTypes>(
      apiRoute.calcItem.addSection,
      {},
      {
        name: sectionName,
        isRequired: 0,
      },
      getAccessToken(),
    );

    if (config.status === 201) {
      setSectionList((prev) => [...prev, data]);
    } else {
      alertMessage('추가에 실패했습니다');
    }
  }, [sectionName, getAccessToken]);

  useEffect(() => {
    loadSectionList();
  }, [loadSectionList]);

  return (
    <CalcItem
      sectionList={sectionList}
      onSectionDeleted={onSectionDeleted}
      setSectionName={setSectionName}
      onSectionAddClicked={onSectionAddClicked}
    />
  );
};

export default CalcItemContainer;
