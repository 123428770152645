import images from '@assets/images';
import React from 'react';
import './styles/cselect.styles.css';

type Props<T> = {
  optionVisible: boolean;
  onSelectClicked: () => void;
  selected: string;
  options: T[];
  onSelectItem: (selected: T) => void;
  labelSelector: (item: T) => string;
  className?: string;
};

function CSelect<T>({
  optionVisible,
  onSelectClicked,
  selected,
  options,
  onSelectItem,
  labelSelector,
  className,
}: Props<T>) {
  return (
    <div className={'cselect-root ' + className}>
      <div className='selected-section' onClick={onSelectClicked}>
        <span>{selected}</span>
        <div>
          <img src={images.common.cselect.select_icon} />
        </div>
      </div>
      {optionVisible && (
        <div className='option-section'>
          {options.map((item, index) => (
            <span
              onClick={() => onSelectItem(item)}
              key={index}
              className='option-item'>
              {labelSelector(item)}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

export default CSelect;
