export default function useToken() {
  const getAccessToken = () => {
    return sessionStorage.getItem('@accessToken') || 'NO_TOKEN';
  };

  const setAccessToken = (accessToken: string) => {
    sessionStorage.setItem('@accessToken', accessToken);
  };

  const getRefreshToken = () => {
    return sessionStorage.getItem('@refreshToken') || 'NO_TOKEN';
  };

  const setRefreshToken = (refreshToken: string) => {
    sessionStorage.setItem('@refreshToken', refreshToken);
  };

  return {
    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken,
  };
}
