import { ProgramTypes } from '@typedef/components/Program/program.types';
import { ProgramListActionTypes } from './actions';
import {
  ADD_PROGRAM,
  CLEAR_PROGRAM_LIST,
  UPDATE_PROGRAM_LIST,
} from './modules/actionTypes';

export type ProgramListStateType = {
  list: ProgramTypes[];
};

const initialState: ProgramListStateType = {
  list: [],
};

const programListReducer = (
  prev: ProgramListStateType = initialState,
  { type, payload }: ProgramListActionTypes,
): ProgramListStateType => {
  switch (type) {
    case UPDATE_PROGRAM_LIST:
      return {
        list: payload as ProgramTypes[],
      };
    case CLEAR_PROGRAM_LIST:
      return initialState;
    case ADD_PROGRAM:
      return {
        list: [...prev.list, payload as ProgramTypes],
      };
    default:
      return prev;
  }
};

export default programListReducer;
