import FlatListContainer from '@lib/FlatList/containers/FlatListContainer';
import { QnaType } from '@typedef/components/Qna/qna.types';
import React, { RefObject } from 'react';
import QnaCardItemContainer from './containers/QnaCardItemContainer';
import './styles/qna.list.style.css';

type Props = {
  qnaList: QnaType[];
  onAddQna: () => void;
  onEndReached: () => void;
};

const Qna = ({ qnaList, onAddQna, onEndReached }: Props) => {
  return (
    <div className='qna-root'>
      <div className='title-section'>
        <div className='title'>{'Q&A 관리'}</div>
      </div>
      <div className='content-section'>
        <FlatListContainer
          containerClassName='list-section'
          data={qnaList}
          renderItem={(item, index) => {
            return <QnaCardItemContainer key={index} item={item} />;
          }}
          onEndReached={onEndReached}
          threshold={0.7}
        />
        <button className='add-button' onClick={onAddQna}>
          <span>질문추가</span>
        </button>
      </div>
    </div>
  );
};

export default Qna;
