import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, BasicListApiResponse, requestSecureGet } from '@lib/api';
import { ApplyItemType } from '@typedef/components/Apply/apply.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Apply from '../Apply';

const ApplyContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();

  const [applyList, setApplyList] = useState<ApplyItemType[]>([]);

  const loadApplyList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<ApplyItemType[]>
    >(apiRoute.apply.getApplyList, {}, getAccessToken());

    if (config.status === 200) {
      setApplyList(data.data);
    } else {
      alertMessage('정보를 불러오지 못했습니다');
    }
  }, [getAccessToken]);

  const onSearchClicked = useCallback(() => {}, []);

  const onListItemClicked = useCallback(
    (index: number) => {
      navigate('detail', {
        state: {
          data: applyList[index],
        },
      });
    },
    [applyList],
  );

  useEffect(() => {
    loadApplyList();
  }, []);

  return (
    <Apply
      applyList={applyList}
      onSearchClicked={onSearchClicked}
      onListItemClicked={onListItemClicked}
    />
  );
};

export default ApplyContainer;
