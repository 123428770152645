import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import React from 'react';
import '../styles/group.add.styles.css';

type Props = {
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  setGroupUid: React.Dispatch<React.SetStateAction<string>>;
  setRemark: React.Dispatch<React.SetStateAction<string>>;
  onCancelBtnClicked: () => void;
  onAddBtnClicked: () => void;
};

const GroupAddPopup = ({
  setGroupName,
  setGroupUid,
  setRemark,
  onCancelBtnClicked,
  onAddBtnClicked,
}: Props) => {
  return (
    <div onClick={(e) => e.stopPropagation()} className='group-add-popup-root'>
      <div className='input-section'>
        <div className='input-item'>
          <div className='input-label'>권한그룹 이름</div>
          <CInputContainer
            className='input'
            onValueChanged={setGroupName}
            testValue={() => true}
          />
        </div>
        <div className='input-item'>
          <div className='input-label'>권한그룹 UID</div>
          <CInputContainer
            className='input'
            onValueChanged={setGroupUid}
            testValue={() => true}
          />
        </div>
      </div>
      <div className='input-section'>
        <div className='input-item'>
          <div className='input-label'>프로그램 설명</div>
          <CInputContainer
            className='input'
            onValueChanged={setRemark}
            testValue={() => true}
          />
        </div>
      </div>
      <div className='button-section'>
        <button onClick={onCancelBtnClicked} className='cancel-btn'>
          취소하기
        </button>
        <button onClick={onAddBtnClicked} className='add-btn'>
          추가하기
        </button>
      </div>
    </div>
  );
};

export default GroupAddPopup;
