import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, BasicListApiResponse, requestSecureGet } from '@lib/api';
import { RequestItemType } from '@typedef/components/Request/request.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Request from '../Request';

const RequestContainer = () => {
  const naviagate = useNavigate();

  const { getAccessToken } = useToken();

  const [requests, setRequests] = useState<RequestItemType[]>([]);

  const loadRequest = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<RequestItemType[]>
    >(apiRoute.request.getRequests, {}, getAccessToken());

    if (config.status === 200) {
      setRequests(data.data);
    } else {
      alertMessage('목록을 불러오는데 실패했습니다');
    }
  }, [getAccessToken]);

  const onListItemClicked = useCallback(
    (index: number) => {
      naviagate('detail', {
        state: {
          data: requests[index],
        },
      });
    },
    [requests, naviagate],
  );

  const onSearchClicked = useCallback(async (keyword: string) => {}, []);

  useEffect(() => {
    loadRequest();
  }, []);

  return (
    <Request
      requests={requests}
      onSearchClicked={onSearchClicked}
      onListItemClicked={onListItemClicked}
    />
  );
};

export default RequestContainer;
