import useGNB from '@hooks/component/common/useGNB';
import useRootRoute from '@hooks/routes/useRootRoute';
import useUser from '@hooks/store/useUser';
import useToken from '@hooks/useToken';
import { apiRoute, requestSecurePost } from '@lib/api';
import HomeNavigation from '@routes/components/HomeNavigation';
import { LoginResponseDataType } from '@typedef/components/Login/login.types';
import React, { useCallback, useEffect, useMemo } from 'react';

const HomeNavigationContainer = () => {
  const { getGNB } = useGNB();
  const { __updateRootFromHooks } = useRootRoute();

  const { user, __updateUserFromHooks } = useUser();
  const { getRefreshToken, setRefreshToken, setAccessToken, getAccessToken } =
    useToken();

  const userProgram = useMemo(() => {
    return getGNB();
  }, [getGNB]);

  const checkUserData = useCallback(async () => {
    if (Object.values(user).includes('NO_DATA')) {
      const {
        data,
        config: { status },
      } = await requestSecurePost<LoginResponseDataType>(
        apiRoute.auth.refresh,
        {},
        {},
        getRefreshToken(),
      );

      if (status === 200) {
        setAccessToken(data.tokenPayload.accessToken);
        setRefreshToken(data.tokenPayload.refreshToken);

        __updateUserFromHooks(data.tokenizedUserPayloadDto);
      } else {
        setAccessToken('NO_TOKEN');
        setRefreshToken('NO_TOKEN');
        __updateUserFromHooks({
          id: 'NO_DATA',
          userName: 'NO_DATA',
          displayName: 'NO_DATA',
          role: 'NO_DATA',
        });

        __updateRootFromHooks('login');
        alert('로그인이 만료 되었습니다');
      }
    }
  }, [
    user,
    getRefreshToken,
    setAccessToken,
    setRefreshToken,
    __updateUserFromHooks,
    __updateRootFromHooks,
  ]);

  useEffect(() => {
    checkUserData();
  }, [checkUserData]);

  useEffect(() => {
    console.log('[Logger]', 'Token', getAccessToken());
  }, [getAccessToken]);

  return <HomeNavigation userProgram={userProgram} />;
};

export default HomeNavigationContainer;
