import images from '@assets/images';
import React, { RefObject } from 'react';
import './styles/csearch.styles.css';

type Props = {
  onSearchBtnClicked: () => void;
  placeholder: string;
  inputRef: RefObject<HTMLInputElement>;
  onDeleteBtnClicked: () => void;
  deleteBtnVisible: boolean;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
};

const CSearch = ({
  onSearchBtnClicked,
  placeholder,
  inputRef,
  onDeleteBtnClicked,
  deleteBtnVisible,
  setKeyword,
  className,
}: Props) => {
  return (
    <div className={'csearch-root ' + className}>
      <div className='csearch-input-section'>
        <input
          ref={inputRef}
          className='search-input'
          type='text'
          placeholder={placeholder}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
        <button className='search-btn' onClick={onSearchBtnClicked}>
          검색
        </button>
        {deleteBtnVisible && (
          <button className='delete-btn' onClick={onDeleteBtnClicked}>
            <img src={images.common.csearch.delete_btn} />
          </button>
        )}
      </div>
    </div>
  );
};

export default CSearch;
