import usePopup from '@hooks/popup/usePopup';
import usePrograms from '@hooks/store/usePrograms';
import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, BasicListApiResponse, requestSecureGet } from '@lib/api';
import { ProgramTypes } from '@typedef/components/Program/program.types';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Program from '../Program';
import ProgramAddPopupContainer from './ProgramAddPopupContainer';

const ProgramContainer = () => {
  const { __showPopupFromHooks } = usePopup();
  const {
    programs,
    __updateProgramListFromHooks,
    __clearProgramListFromHooks,
  } = usePrograms();

  const navigate = useNavigate();

  const { getAccessToken } = useToken();

  const loadPrograms = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<ProgramTypes[]>
    >(apiRoute.program.getProgramList, {}, getAccessToken());

    if (config.status === 200) {
      __updateProgramListFromHooks(data.data);
    } else {
      alertMessage('목록을 불러오지 못헀습니다');
    }
  }, [getAccessToken]);

  const onListItemClicked = useCallback(
    async (item: ProgramTypes) => {
      navigate('detail', {
        state: {
          data: item,
        },
      });
    },
    [navigate],
  );

  const onAddBtnClicked = useCallback(() => {
    __showPopupFromHooks(<ProgramAddPopupContainer />);
  }, []);

  useEffect(() => {
    loadPrograms();

    return () => {
      __clearProgramListFromHooks();
    };
  }, [__clearProgramListFromHooks]);

  return (
    <Program
      programs={programs}
      onListItemClicked={onListItemClicked}
      onAddBtnClicked={onAddBtnClicked}
    />
  );
};

export default ProgramContainer;
