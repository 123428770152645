import { addMenu, clearMenuList, updateMenuList } from '@store/menu/actions';
import { RootState } from '@store/rootReducer';
import { MenuItemType } from '@typedef/components/Menu/menu.types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useMenu() {
  const menu = useSelector((root: RootState) => root.menuListReducer.list);

  const dispatch = useDispatch();

  const __updateMenuFromHooks = useCallback(
    (diff: MenuItemType[]) => dispatch(updateMenuList(diff)),
    [dispatch],
  );

  const __clearMenuListFromHooks = useCallback(
    () => dispatch(clearMenuList()),
    [dispatch],
  );

  const __addMenuListFromHooks = useCallback(
    (diff: MenuItemType) => dispatch(addMenu(diff)),
    [dispatch],
  );

  return {
    menu,
    __updateMenuFromHooks,
    __clearMenuListFromHooks,
    __addMenuListFromHooks,
  };
}
