import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import React from 'react';
import images from 'src/assets/images';
import './styles/login.styles.css';

type Props = {
  onLoginBtnClicked: () => void;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setPw: React.Dispatch<React.SetStateAction<string>>;
};

const Login = ({ onLoginBtnClicked, setId, setPw }: Props) => {
  return (
    <div className='login-root'>
      <div className='logo-section'>
        <img src={images.login.logo} alt='wim-login-logo' />
      </div>
      <div className='input-section'>
        <CInputContainer
          className='login-input'
          onValueChanged={(val) => {
            setId(val);
          }}
          testValue={() => true}
          placeholder='아이디를 입력해주세요'
        />
        <CInputContainer
          className='login-input'
          onValueChanged={(val) => {
            setPw(val);
          }}
          testValue={() => true}
          placeholder='비밀번호를 입력해주세요'
          type='password'
        />
        <button onClick={onLoginBtnClicked} className='login-input login-btn'>
          로그인
        </button>
      </div>
    </div>
  );
};

export default Login;
