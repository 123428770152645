import useGNB from '@hooks/component/common/useGNB';
import useRootRoute from '@hooks/routes/useRootRoute';
import useUser from '@hooks/store/useUser';
import useToken from '@hooks/useToken';
import { apiRoute, requestPost } from '@lib/api';
import { LoginResponseDataType } from '@typedef/components/Login/login.types';
import React, { useCallback, useEffect, useState } from 'react';
import Login from '../Login';

const LoginContainer = () => {
  const { setGNB } = useGNB();
  const { __updateRootFromHooks } = useRootRoute();

  const { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } =
    useToken();

  const { __updateUserFromHooks } = useUser();

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  const checkSessionToken = useCallback(() => {
    if (getAccessToken() !== 'NO_TOKEN' && getRefreshToken() !== 'NO_TOKEN') {
      __updateRootFromHooks('main');
    }
  }, [getAccessToken, getRefreshToken, __updateRootFromHooks]);

  const onLoginBtnClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestPost<LoginResponseDataType>(
      apiRoute.auth.login,
      {},
      {
        userName: id,
        password: pw,
      },
    );

    if (status === 200) {
      setAccessToken(data.tokenPayload.accessToken);
      setRefreshToken(data.tokenPayload.refreshToken);
      setGNB(data.tokenizedUserPayloadDto.userProgram);

      __updateUserFromHooks(data.tokenizedUserPayloadDto);
      __updateRootFromHooks('main');
    } else {
      alert('아이디 또는 비밀번호가 다릅니다');
    }
  }, [id, pw, __updateUserFromHooks, __updateRootFromHooks]);

  useEffect(() => {
    checkSessionToken();
  }, [checkSessionToken]);

  return (
    <Login setId={setId} setPw={setPw} onLoginBtnClicked={onLoginBtnClicked} />
  );
};

export default LoginContainer;
