import React from 'react';
import images from 'src/assets/images';
import './styles/gnb.styles.css';

type Props = {
  tab: string;
  menuList: string[];
  onTabClicked: (tab: string) => void;
};

const GNB = ({ tab, menuList, onTabClicked }: Props) => {
  return (
    <div className='gnb-root'>
      <div className='gnb-logo-section'>
        <img src={images.login.logo} alt='gnb-logo' />
      </div>

      <div className='gnb-list-section'>
        {menuList.map((val, index) => (
          <div
            key={index}
            className={`gnb-item ${val === tab ? 'selected' : ''}`}
            onClick={() => onTabClicked(val)}>
            <span className='bar'></span>
            <span className='gnb-title'>{val}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GNB;
