import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import {
  apiRoute,
  BasicListApiResponse,
  requestGet,
  requestMultipart,
  requestSecurePatch,
} from '@lib/api';
import { MainBannerTypes } from '@typedef/components/MainBanner/main.banner.types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MainBanner from '../MainBanner';

const MainBannerContainer = () => {
  const { getAccessToken } = useToken();

  const [banners, setBanners] = useState<MainBannerTypes[]>([]);

  const [currentBanner, setCurrentBanner] = useState<MainBannerTypes | null>(
    null,
  );
  const [url, setUrl] = useState('');

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const loadCurrentMainBanner = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestGet<BasicListApiResponse<MainBannerTypes[]>>(
      apiRoute.banner.getBanners + `?isPosted=1`,
      {},
    );

    if (status === 200) {
      setCurrentBanner(data.data[0]);
    }
  }, []);

  const loadMainBanner = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestGet<BasicListApiResponse<MainBannerTypes[]>>(
      apiRoute.banner.getBanners + `?page=1&take=8`,
      {},
    );

    if (status === 200) {
      setBanners(data.data);
    }
  }, []);

  const onDateSelected = useCallback(async (selectedDate: Date) => {
    const {
      data,
      config: { status },
    } = await requestGet<BasicListApiResponse<MainBannerTypes[]>>(
      apiRoute.banner.getBanners +
        `?page=1&take=8&${
          selectedDate === null
            ? ''
            : 'createdAt=' + selectedDate.getTime() + '&'
        }`,
      {},
    );

    if (status === 200) {
      setBanners(data.data);
    } else {
      setBanners([]);
    }
  }, []);

  const onURLApplyClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecurePatch<MainBannerTypes>(
      apiRoute.banner.updateBanners + '/' + currentBanner?.id,
      {},
      {
        referenceUrl: url,
      },
      getAccessToken(),
    );

    if (status === 202) {
      setCurrentBanner(data);
      setBanners((prev) =>
        prev.map((item) =>
          item.isPosted
            ? {
                ...item,
                referenceUrl: url,
              }
            : item,
        ),
      );
    } else {
      alertMessage('URL 변경에 실패했습니다');
    }
  }, [currentBanner, getAccessToken, url]);

  const onFileSelectClicked = useCallback(() => {
    inputFileRef?.current?.click();
  }, [inputFileRef]);

  const onPostingClicked = useCallback(
    async (index: number) => {
      const {
        data,
        config: { status },
      } = await requestSecurePatch<MainBannerTypes>(
        apiRoute.banner.updateBanners + '/' + banners[index]?.id,
        {},
        {
          isPosted: 1,
        },
        getAccessToken(),
      );

      if (status === 202) {
        loadMainBanner();
        setCurrentBanner(data);
      } else {
        alertMessage('게시 여부 변경에 실패했습니다');
      }
    },
    [banners, getAccessToken, loadMainBanner],
  );

  const onAddBannerClicked = useCallback(async () => {
    if (selectedFile === null) {
      alertMessage('파일을 선택해주세요');
      return;
    }

    const form = new FormData();

    form.append(
      'extensionKor',
      selectedFile.name?.endsWith('.mp4') ? '영상' : '이미지',
    );
    form.append('referenceUrl', 'https://www.google.com');
    form.append('bannerFile', selectedFile);

    const {
      data,
      config: { status },
    } = await requestMultipart<MainBannerTypes>(
      apiRoute.banner.addBanners,
      {},
      form,
      getAccessToken(),
    );

    if (status === 201) {
      const {
        data: updatedBanner,
        config: { status },
      } = await requestSecurePatch<MainBannerTypes>(
        apiRoute.banner.updateBanners + '/' + data.id,
        {},
        {
          isPosted: 1,
        },
        getAccessToken(),
      );

      if (status === 202) {
        setCurrentBanner(updatedBanner);
      } else {
        alertMessage('배너 게시에 실패 했습니다');
      }
    } else {
      alertMessage('배너 올리기에 실패 했습니다');
    }
  }, [selectedFile, getAccessToken]);

  useEffect(() => {
    loadCurrentMainBanner();
  }, [loadCurrentMainBanner]);

  useEffect(() => {
    loadMainBanner();
  }, [loadMainBanner]);

  return (
    <MainBanner
      currentBanner={currentBanner}
      banners={banners}
      onDateSelected={onDateSelected}
      setUrl={setUrl}
      onURLApplyClicked={onURLApplyClicked}
      onPostingClicked={onPostingClicked}
      inputFileRef={inputFileRef}
      onFileSelectClicked={onFileSelectClicked}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      onAddBannerClicked={onAddBannerClicked}
    />
  );
};

export default MainBannerContainer;
