import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, requestSecurePatch } from '@lib/api';
import { ApplyItemType } from '@typedef/components/Apply/apply.types';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ApplyDetail from '../components/ApplyDetail';

const ApplyDetailContainer = () => {
  const { state } = useLocation() as {
    state: {
      data: ApplyItemType;
    };
  };

  const [applyData, setApplyData] = useState(state.data);

  const { getAccessToken } = useToken();

  const onConfirmCheckChanged = useCallback(
    async (checked: boolean) => {
      const { data, config } = await requestSecurePatch<ApplyItemType>(
        apiRoute.apply.updateApply + '/' + applyData.id,
        {},
        {
          isConfirmed: checked ? 1 : 0,
        },
        getAccessToken(),
      );

      if (config.status === 202) {
        setApplyData(data);
      } else {
        alertMessage('정보 변경에 실패 했습니다');
      }
    },
    [applyData, getAccessToken],
  );

  return (
    <ApplyDetail
      data={applyData}
      onConfirmCheckChanged={onConfirmCheckChanged}
    />
  );
};

export default ApplyDetailContainer;
