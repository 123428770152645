import CCheckBoxContainer from '@components/common/CCheckBox/containers/CCheckBoxContainer';
import { formatPrice } from '@lib/factory';
import { SectionItemTypes } from '@typedef/components/CalcItem/calc.item.types';
import React, { RefObject } from 'react';
import '../styles/section.item.styles.css';

type Props = {
  data: SectionItemTypes;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteBtnClicked: () => void;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setSubName: React.Dispatch<React.SetStateAction<string>>;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  onAddBtnClicked: () => void;
  nameInpuRef: RefObject<HTMLInputElement>;
  subNameInpuRef: RefObject<HTMLInputElement>;
  priceInpuRef: RefObject<HTMLInputElement>;
};

const SectionItem = ({
  data,
  checked,
  setChecked,
  onDeleteBtnClicked,
  setName,
  setSubName,
  setPrice,
  onAddBtnClicked,
  nameInpuRef,
  subNameInpuRef,
  priceInpuRef,
}: Props) => {
  console.log(data);

  return (
    <div className='section-item-root'>
      <div className='title-section'>
        <div className='title'>{data.name}</div>
        <div className='btn-section'>
          <CCheckBoxContainer
            label='필수 선택 여부'
            onChange={setChecked}
            initialValue={checked}
          />
          <button className='delete-btn' onClick={onDeleteBtnClicked}>
            삭제
          </button>
        </div>
      </div>
      <div className='item-list-section'>
        {data.inquiryEstimateCategories.map((item, idx) => (
          <div key={idx} className='item-root'>
            <div className='title'>{item.name}</div>
            <div className='sub-title'>{item.subName}</div>
            <div className='sub-title'>{formatPrice(item.price)}원</div>
          </div>
        ))}
        <div className='item-root add-item'>
          <input
            ref={nameInpuRef}
            type='text'
            className='name-input'
            onChange={(e) => setName(e.target.value)}
            placeholder='이름을 입력해주세요'
          />
          <input
            ref={subNameInpuRef}
            type='text'
            className='sub-name-input'
            onChange={(e) => setSubName(e.target.value)}
            placeholder='설명을 입력해주세요'
          />
          <input
            ref={priceInpuRef}
            type='number'
            className='sub-name-input'
            onChange={(e) => setPrice(e.target.value)}
            placeholder='가격을 입력해주세요'
          />
          <div onClick={onAddBtnClicked} className='wing'>
            추가
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionItem;
