import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { PostTypes } from '@typedef/components/CardNews/card.news.types';
import React from 'react';
import '../styles/post.select.styles.css';
import SearchPostCard from './SearchPostCard';

type Props = {
  posts: PostTypes[];
  onChangeSelected: (newPostId: string) => void;
};

const PostSelect = ({ posts, onChangeSelected }: Props) => {
  return (
    <div
      className='post-select-root'
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <div className='input-section'>
        <CSelectContainer
          onSelected={() => {}}
          initialSelected='글 카테고리'
          options={['option1', 'option2']}
          labelSelector={(item) => item}
        />
        <div className='space'></div>
        <CSearchContainer
          onSearchClicked={() => {}}
          placeholder='게시글 명을 입력해주세요'
        />
      </div>
      <div className='post-list-container'>
        {posts.map((item, index) => (
          <SearchPostCard
            key={index}
            onClick={() => onChangeSelected(item.id)}
            data={item}
          />
        ))}
      </div>
    </div>
  );
};

export default PostSelect;
