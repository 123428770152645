import React from 'react';
import '../styles/popup.styles.css';

type Props = {
  children: React.ReactNode;
  onBackdropClicked: () => void;
};

const Popup = ({ children, onBackdropClicked }: Props) => {
  return (
    <div className='popup-root' onClick={onBackdropClicked}>
      {children}
    </div>
  );
};

export default Popup;
