import {
  addGroup,
  clearGroupList,
  updateGroupList,
} from '@store/group/actions';
import { RootState } from '@store/rootReducer';
import { GroupItemTypes } from '@typedef/components/Group/group.types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useGroup() {
  const groupList = useSelector(
    (root: RootState) => root.groupListReducer.list,
  );

  const dispatch = useDispatch();

  const __updateGroupListFromHooks = useCallback(
    (diff: GroupItemTypes[]) => dispatch(updateGroupList(diff)),
    [dispatch],
  );

  const __clearGroupListFromHooks = useCallback(
    () => dispatch(clearGroupList()),
    [dispatch],
  );

  const __addGroupFromHooks = useCallback(
    (diff: GroupItemTypes) => dispatch(addGroup(diff)),
    [dispatch],
  );

  return {
    groupList,
    __updateGroupListFromHooks,
    __clearGroupListFromHooks,
    __addGroupFromHooks,
  };
}
