import SearchPostCard from '@components/CardNews/components/SearchPostCard';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import {
  PostCategoryTypes,
  PostTypes,
} from '@typedef/components/CardNews/card.news.types';
import React from 'react';
import './styles/post.styles.css';

type Props = {
  setBigCat: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSmallCat: React.Dispatch<
    React.SetStateAction<PostCategoryTypes | undefined>
  >;
  smallOptions: PostCategoryTypes[];
  posts: PostTypes[];
};

const Post = ({ setBigCat, setSmallCat, smallOptions, posts }: Props) => {
  return (
    <div className='post-root'>
      <div className='input-section'>
        <CSelectContainer
          onSelected={setBigCat}
          initialSelected='글 대분류'
          options={['WEBAPP', 'AI']}
          labelSelector={(item) => item}
        />
        <CSelectContainer
          onSelected={setSmallCat}
          initialSelected='글 소분류'
          options={smallOptions}
          labelSelector={(item) => item.name}
        />
        <div className='space'></div>
        <CSearchContainer
          onSearchClicked={() => {}}
          placeholder='게시글 명을 입력해주세요'
          className='post-input'
        />
      </div>
      <div className='post-list-section'>
        {posts.map((item, index) => (
          <SearchPostCard onClick={() => {}} data={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Post;
