import { MenuItemType } from '@typedef/components/Menu/menu.types';
import {
  ADD_MENU,
  CLEAR_MENU_LIST,
  UPDATE_MENU_LIST,
} from './modules/actionTypes';

export const updateMenuList = (list: MenuItemType[]) => ({
  type: UPDATE_MENU_LIST,
  payload: list,
});

export const clearMenuList = () => ({
  type: CLEAR_MENU_LIST,
  payload: [],
});

export const addMenu = (item: MenuItemType) => ({
  type: ADD_MENU,
  payload: item,
});

export type MenuListActionTypes =
  | ReturnType<typeof updateMenuList>
  | ReturnType<typeof clearMenuList>
  | ReturnType<typeof addMenu>;
