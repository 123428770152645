import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { GroupItemTypes } from '@typedef/components/Group/group.types';
import { ProgramTypes } from '@typedef/components/Program/program.types';
import React from 'react';
import '../styles/menu.add.popup.styles.css';

type Props = {
  programs: ProgramTypes[];
  setSelectedProgram: React.Dispatch<React.SetStateAction<ProgramTypes | null>>;
  groups: GroupItemTypes[];
  setSelectedGroup: React.Dispatch<React.SetStateAction<GroupItemTypes | null>>;
  setMenuName: React.Dispatch<React.SetStateAction<string>>;
  onCancelBtnClicked: () => void;
  onAddBtnClicked: () => void;
};

const MenuAddPopup = ({
  programs,
  setSelectedProgram,
  groups,
  setSelectedGroup,
  setMenuName,
  onCancelBtnClicked,
  onAddBtnClicked,
}: Props) => {
  return (
    <div onClick={(e) => e.stopPropagation()} className='menu-add-popup-root'>
      <div className='input-section'>
        <div className='input-item'>
          <div className='input-label'>프로그램 UID</div>
          <CSelectContainer
            className='input'
            onSelected={setSelectedProgram}
            options={programs}
            initialSelected={'권한 그룹을 선택해주세요'}
            labelSelector={(item) => item.name}
          />
        </div>
        <div className='input-item'>
          <div className='input-label'>권한그룹 UID</div>
          <CSelectContainer
            className='input'
            onSelected={setSelectedGroup}
            options={groups}
            initialSelected={'권한 그룹을 선택해주세요'}
            labelSelector={(item) => item.name}
          />
        </div>
      </div>
      <div className='input-section'>
        <div className='input-item'>
          <div className='input-label'>메뉴 이름</div>
          <CInputContainer
            className='input'
            onValueChanged={setMenuName}
            testValue={() => true}
          />
        </div>
      </div>
      <div className='button-section'>
        <button onClick={onCancelBtnClicked} className='cancel-btn'>
          취소하기
        </button>
        <button onClick={onAddBtnClicked} className='add-btn'>
          추가하기
        </button>
      </div>
    </div>
  );
};

export default MenuAddPopup;
