import React from 'react';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from '@store/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import RootNavigationContainer from 'src/routes/containers/RootNavigationContainer';
import createSaga from 'redux-saga';
import rootSaga from 'src/saga/rootSaga';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

const saga = createSaga();

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(saga)),
);

saga.run(rootSaga);

const App = () => {
  return (
    <Provider store={store}>
      <RootNavigationContainer />
    </Provider>
  );
};

export default App;
