import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import { RequestItemType } from '@typedef/components/Request/request.types';
import React from 'react';
import './styles/request.styles.css';

type Props = {
  requests: RequestItemType[];
  onSearchClicked: (keyword: string) => void;
  onListItemClicked: (index: number) => void;
};

const Request = ({ requests, onSearchClicked, onListItemClicked }: Props) => {
  return (
    <div className='request-root'>
      <div className='search-section'>
        <CSearchContainer
          onSearchClicked={onSearchClicked}
          placeholder='이름을 입력해주세요'
          className='name-input'
        />
      </div>
      <div className='list-section'>
        <div className='header-article'>
          <span className='header-item'>프로젝트 명</span>
          <span className='header-item'>예산 범위</span>
          <span className='header-item'>예상 개발 기간</span>
          <span className='header-item'>기획 검토</span>
          <span className='header-item'>디자인 검토</span>
          <span className='header-item'>개발 검토</span>
        </div>
        <div className='body-article'>
          {requests.map((item, index) => (
            <div
              onClick={() => onListItemClicked(index)}
              key={index}
              className='list-item'>
              <span className='item-label'>{item.projectName}</span>
              <span className='item-label'>{item.budget}</span>
              <span className='item-label'>{item.term}</span>
              <span className='item-label'>
                {item.planConfirmedAt ? '검토' : '미검토'}
              </span>
              <span className='item-label'>
                {item.designConfirmedAt ? '검토' : '미검토'}
              </span>
              <span className='item-label'>
                {item.developmentConfirmedAt ? '검토' : '미검토'}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Request;
