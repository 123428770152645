import CCheckBoxContainer from '@components/common/CCheckBox/containers/CCheckBoxContainer';
import { RequestItemType } from '@typedef/components/Request/request.types';
import React from 'react';
import '../styles/request.detail.styles.css';

type Props = {
  data: RequestItemType;
};

const RequstDetail = ({ data }: Props) => {
  return (
    <div className='request-detail-root'>
      {data.isPlanned === 1 && (
        <div className='info-section'>
          <div className='title-article'>
            <span className='title'>기획</span>
            <CCheckBoxContainer
              label='체크 여부'
              onChange={() => {}}
              initialValue={false}
            />
          </div>
          <div className='content-article'>
            <div className='file-1'></div>
            <div className='file-2'></div>
          </div>
        </div>
      )}
      {data.isDesigned === 1 && (
        <div className='info-section'>
          <div className='title-article'>
            <span className='title'>디자인</span>
            <CCheckBoxContainer
              label='체크 여부'
              onChange={() => {}}
              initialValue={false}
            />
          </div>
          <div className='content-article'>
            <div className='file-1'></div>
            <div className='file-2'></div>
          </div>
        </div>
      )}
      <div className='info-section'>
        <div className='title-article'>
          <span className='title'>개발</span>
          <CCheckBoxContainer
            label='체크 여부'
            onChange={() => {}}
            initialValue={false}
          />
        </div>
        <div className='content-article'>
          <div className='info-item'>
            <div className='label-item'>
              <div className='label-title'>프로젝트 이름</div>
              <div className='label-content'>{data.projectName}</div>
            </div>
            <div className='label-item'>
              <div className='label-title'>예산 범위</div>
              <div className='label-content'>{data.budget}</div>
            </div>
            <div className='label-item'>
              <div className='label-title'>예상 개발 기간</div>
              <div className='label-content'>{data.term}</div>
            </div>
          </div>
          <div className='info-item'>
            <div className='label-item'>
              <div className='label-title'>출시 플랫폼</div>
              <div className='label-content'>
                앱 {data.releasePlatformApp === 1 ? '있음' : '없음'}
              </div>
              <div className='label-content'>
                웹 {data.releasePlatformWeb === 1 ? '있음' : '없음'}
              </div>
            </div>
            <div className='label-item'>
              <div className='label-title'>정부지원사업 집행 여부</div>
              <div className='label-content'>{data.governmentProject}</div>
            </div>
            <div className='label-item'>
              <div className='label-title'>서비스 설명</div>
              <div className='label-content'>{data.remark}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='info-section'>
        <div className='title-article'>
          <span className='title'>연락처</span>
        </div>
        <div className='content-article'>
          <div className='info-item'>
            <div className='label-item'>
              <div className='label-title'>이름</div>
              <div className='label-content'>{data.name}</div>
            </div>
            <div className='label-item'>
              <div className='label-title'>연락처</div>
              <div className='label-content'>{data.phone}</div>
            </div>
            <div className='label-item'>
              <div className='label-title'>이메일</div>
              <div className='label-content'>{data.email}</div>
            </div>
          </div>
          <div className='info-item'>
            <div className='label-item'>
              <div className='label-title'>회사이름</div>
              <div className='label-content'>{data.companyName}</div>
            </div>
            <div className='label-item'>
              <div className='label-title'>직책</div>
              <div className='label-content'>{data.position}</div>
            </div>
            <div className='label-item'>
              <div className='label-title'>연락 선호 시간</div>
              <div className='label-content'>{data.contactTime}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequstDetail;
