import React, { HTMLInputTypeAttribute, RefObject } from 'react';
import './styles/cinput.styles.css';

type Props = {
  setInput: React.Dispatch<React.SetStateAction<string>>;
  inputRef: RefObject<HTMLInputElement>;
  setIsFocusing: React.Dispatch<React.SetStateAction<boolean>>;
  className: string;
  placeholder: string;
  type: HTMLInputTypeAttribute;
  disabled: boolean;
};

const CInput = ({
  setInput,
  inputRef,
  setIsFocusing,
  className,
  placeholder,
  type,
  disabled,
}: Props) => {
  return (
    <input
      ref={inputRef}
      className={'cinput ' + className}
      onChange={(e) => {
        setInput(e.target.value);
      }}
      onFocus={() => {
        setIsFocusing(true);
      }}
      onBlur={() => {
        setIsFocusing(false);
      }}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
  );
};

export default CInput;
