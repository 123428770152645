import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { GroupItemTypes } from '@typedef/components/Group/group.types';
import React from 'react';
import '../styles/program.add.popup.styles.css';

type Props = {
  setProgramName: React.Dispatch<React.SetStateAction<string>>;
  setProgramUid: React.Dispatch<React.SetStateAction<string>>;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  setRemark: React.Dispatch<React.SetStateAction<string>>;
  onAddBtnClicked: () => void;
  onCancelBtnClicked: () => void;
};

const ProgramAddPopup = ({
  setProgramName,
  setProgramUid,
  setUrl,
  setRemark,
  onAddBtnClicked,
  onCancelBtnClicked,
}: Props) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className='program-add-popup-root'>
      <div className='input-section'>
        <div className='input-item'>
          <div className='input-label'>프로그램 UID</div>
          <CInputContainer
            className='input'
            onValueChanged={setProgramUid}
            testValue={() => true}
          />
        </div>
        <div className='input-item'>
          <div className='input-label'>프로그램 이름</div>
          <CInputContainer
            className='input'
            onValueChanged={setProgramName}
            testValue={() => true}
          />
        </div>
      </div>
      <div className='input-section'>
        <div className='input-item'>
          <div className='input-label'>프로그램 설명</div>
          <CInputContainer
            className='input'
            onValueChanged={setRemark}
            testValue={() => true}
          />
        </div>
      </div>
      <div className='input-section'>
        <div className='input-item'>
          <div className='input-label'>프로그램 URL</div>
          <CInputContainer
            className='input'
            onValueChanged={setUrl}
            testValue={() => true}
          />
        </div>
      </div>
      <div className='button-section'>
        <button onClick={onCancelBtnClicked} className='cancel-btn'>
          취소하기
        </button>
        <button onClick={onAddBtnClicked} className='add-btn'>
          추가하기
        </button>
      </div>
    </div>
  );
};

export default ProgramAddPopup;
