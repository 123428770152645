import images from '@assets/images';
import { PostTypes } from '@typedef/components/CardNews/card.news.types';
import React, { RefObject } from 'react';
import '../styles/sub.news.card.styles.css';

type Props = {
  cardRootRef: RefObject<HTMLDivElement>;
  post?: PostTypes;
  onClick?: () => void;
};

const SubNewsCard = ({ cardRootRef, post, onClick }: Props) => {
  return (
    <div ref={cardRootRef} className='card-news-root' onClick={onClick}>
      <div className='content'>
        <div className='title'>
          <span>{post ? post.title : '등록된 글이 없습니다'}</span>
        </div>
        <div className='tag'>
          {post && <span>#{post.postCategory.name}</span>}
        </div>
      </div>
      <div className='hover-indicator'>글을 변경하려면 클릭해주세요</div>
    </div>
  );
};

export default SubNewsCard;
