const images = {
  login: {
    logo: '/assets/images/components/login/ic-login-logo.png',
  },
  mainbanner: {
    camera: '/assets/images/components/mainbanner/ic-camera.png',
    check_box_on: '/assets/images/components/mainbanner/ic-check-box.png',
    check_box_off: '/assets/images/components/mainbanner/ic-empty-box.png',
  },
  cardnews: {
    dummy: '/assets/images/components/cardnews/dummy1.png',
  },
  common: {
    cselect: {
      select_icon:
        '/assets/images/components/common/cselect/ic-select-icon.png',
    },
    csearch: {
      delete_btn:
        '/assets/images/components/common/csearch/ic-input-delete.png',
    },
  },
  admin: {
    profile_placeholder: '/assets/images/components/admin/ic-user.png',
    selected: '/assets/images/components/admin/ic-selected.png',
    unslected: '/assets/images/components/admin/ic-unselected.png',
  },
};

export default images;
