import { UserDataTypes } from '@typedef/components/Login/login.types';
import { UserActionTypes } from './actions';
import { UPDATE_USER_ACTION } from './modules/actionTypes';

export type UserStateType = {
  user: UserDataTypes;
};

export const initialState: UserStateType = {
  user: {
    id: 'NO_DATA',
    userName: 'NO_DATA',
    displayName: 'NO_DATA',
    role: 'NO_DATA',
    userProgram: [],
  },
};

const userReducer = (
  prevState: UserStateType = initialState,
  { type, payload }: UserActionTypes,
): UserStateType => {
  switch (type) {
    case UPDATE_USER_ACTION:
      return {
        user: {
          ...prevState.user,
          ...payload,
        },
      };
    default:
      return prevState;
  }
};

export default userReducer;
