import { CommonCodeTypes } from '@typedef/components/CommonCode/commoncode.types';
import React from 'react';
import './styles/common.code.styles.css';

type Props = {
  commonCodeList: CommonCodeTypes[];
  onAddBtnClicked: () => void;
  onListItemClicked: (item: CommonCodeTypes) => void;
};

const CommonCode = ({
  commonCodeList,
  onAddBtnClicked,
  onListItemClicked,
}: Props) => {
  return (
    <div className='common-code-root'>
      <div className='header-section'>
        <div className='title'>공통코드 관리</div>
        <button onClick={onAddBtnClicked} className='add-btn'>
          추가하기
        </button>
      </div>
      <div className='content-section'>
        <div className='list-section'>
          <div className='header-article'>
            <span className='header-item'>코드 값</span>
            <span className='header-item'>그룹 이름</span>
            <span className='header-item'>이름</span>
            <span className='header-item'>설명</span>
          </div>
          <div className='body-article'>
            {commonCodeList.map((item, index) => (
              <div
                onClick={() => onListItemClicked(item)}
                key={index}
                className='list-item'>
                <span className='item-label'>{item.code}</span>
                <span className='item-label'>{item.groupName}</span>
                <span className='item-label'>{item.name}</span>
                <span className='item-label'>{item.remark}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonCode;
