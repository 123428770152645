import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import {
  apiRoute,
  BasicListApiResponse,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@lib/api';
import { SignUpFormType } from '@typedef/components/Admin/admin.types';
import { GroupItemTypes } from '@typedef/components/Group/group.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminCreate from '../components/AdminCreate';

const AdminCreateContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const [profilePath, setProfilePath] = useState<string>();
  const [signUpForm, setSignUpForm] = useState<SignUpFormType>({
    userName: '',
    displayName: '',
    password: '',
    name: '',
    email: '',
    phone: '',
    userFile: null,
  });
  const [options, setOptions] = useState<GroupItemTypes[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupItemTypes | null>(
    null,
  );

  const onInputChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
      setSignUpForm((prev) => ({ ...prev, [key]: e.target.value }));
    },
    [signUpForm],
  );

  const onCancelClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      console.log(e);
      const data = new FormData();

      data.append('userName', signUpForm.userName);
      data.append('displayName', signUpForm.displayName);
      data.append('password', signUpForm.password);
      data.append('name', signUpForm.displayName);
      data.append('phone', signUpForm.phone);
      data.append('email', signUpForm.email);
      if (signUpForm.userFile) {
        data.append('userFile', signUpForm.userFile);
      }

      const {
        data: admin,
        config: { status },
      } = await requestSecurePost<{
        createdAt: string;
        updatedAt: string;
        deletedAt: string | null;
        id: string;
        userName: string;
        displayName: string;
        email: string;
        phone: string;
        profileUrl: string;
      }>(
        apiRoute.admin.registerAdmin,
        {
          'Content-Type': 'multipart/form-data',
        },
        data,
        getAccessToken(),
      );

      if (status === 201) {
        const { config } = await requestSecurePatch<void>(
          apiRoute.auth.update +
            `/${admin.id}?authgroupcode=${selectedGroup?.code}`,
          {},
          {},
          getAccessToken(),
        );

        if (config.status === 202) {
          navigate(-1);
        } else {
          alertMessage('권한 설정에 실패했습니다');
        }
      } else {
        alertMessage('관리사 생성에 실패했습니다');
        return;
      }
    },
    [signUpForm, selectedGroup, getAccessToken],
  );

  const onProfileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setProfilePath(URL.createObjectURL(e.target.files[0]));
        setSignUpForm((prev) => ({ ...prev, userFile: e.target.files![0] }));
      }
    },
    [profilePath],
  );

  const loadOptions = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<GroupItemTypes[]>
    >(apiRoute.group.getGroupList, {}, getAccessToken());

    if (config.status === 200) {
      setOptions(data.data);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [getAccessToken]);

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <AdminCreate
      path={profilePath}
      onInputChanged={onInputChanged}
      signUpForm={signUpForm}
      onCancelClicked={onCancelClicked}
      options={options}
      setSelectedGroup={setSelectedGroup}
      onSubmit={onSubmit}
      onProfileChange={onProfileChange}
    />
  );
};

export default AdminCreateContainer;
