import images from '@assets/images';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import { AdminType } from '@typedef/components/Admin/admin.types';
import React from 'react';
import './style/admin.list.styles.css';

type Props = {
  list: AdminType[];
  onSearch: (keyword: string) => void;
  onCreateClicked: () => void;
};

const Admin = ({ list, onSearch, onCreateClicked }: Props) => {
  return (
    <div className='admin-root'>
      <div className='title-section'>
        <div className='title'>어드민 관리</div>
      </div>
      <div className='content-section'>
        {/* Search Section */}
        <CSearchContainer
          onSearchClicked={(keyword) => onSearch(keyword)}
          placeholder='이름을 입력해주세요'
        />

        {/* List Section */}
        <div className='list-section'>
          <div className='list-header'>
            <div className='header-col'>아이디</div>
            <div className='header-col'>이메일</div>
            <div className='header-col'>연락처</div>
            <div className='header-col'>생성일자</div>
            <div className='header-col'>삭제일자</div>
            <div className='header-col'>프로필</div>
          </div>
          <div className='list-body'>
            {list.map((item, index) => {
              return (
                <div key={index} className='list-row'>
                  <div className='row-item'>{item.displayName}</div>
                  <div className='row-item'>{item.email}</div>
                  <div className='row-item'>{item.phone}</div>
                  <div className='row-item'>{item.createdAt.split('T')[0]}</div>
                  <div className='row-item'>
                    {item.deletedAt?.split('T')[0]}
                  </div>
                  <div className='row-item'>
                    <img
                      src={
                        item.profileUrl
                          ? item.profileUrl
                          : images.admin.profile_placeholder
                      }
                      alt='ic_profile'
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button className='create-button' onClick={onCreateClicked}>
          생성하기
        </button>
      </div>
    </div>
  );
};

export default Admin;
