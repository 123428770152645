export function formatDate(date: Date) {
  return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
}

export function parseDate(dateInputString: string) {
  if (!/^([0-9]{4}-[0-9]{2}-[0-9]{2})$/.test(dateInputString)) {
    throw new Error(
      'The first argument of parseDate function is must be have date format of YYYY-MM-dd',
    );
  }

  const [year, month, date] = dateInputString
    .split('-')
    .map((numString) => parseInt(numString)) as number[];

  return new Date(year, month, date);
}

export function formatPrice(price: string | number) {
  return (price + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
