import alertMessage from '@lib/alert';
import { apiRoute, BasicListApiResponse, requestGet } from '@lib/api';
import { PostTypes } from '@typedef/components/CardNews/card.news.types';
import React, { useCallback, useEffect, useState } from 'react';
import PostSelect from '../components/PostSelect';

type Props = {
  separator: 'MAIN' | 'SUB';
  onChangeSelected: (newPostId: string, separator: 'MAIN' | 'SUB') => void;
};

const PostSelectContainer = ({ separator, onChangeSelected }: Props) => {
  const [posts, setPosts] = useState<PostTypes[]>([]);

  const loadPost = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestGet<BasicListApiResponse<PostTypes[]>>(
      apiRoute.post.getPost,
      {},
    );

    if (status === 200) {
      setPosts(data.data);
    } else {
      alertMessage('글을 불러오지 못했습니다');
    }
  }, []);

  const onCardClicked = useCallback(
    (newPostId: string) => {
      onChangeSelected(newPostId, separator);
    },
    [onChangeSelected, separator],
  );

  useEffect(() => {
    loadPost();
  }, [loadPost]);

  return <PostSelect posts={posts} onChangeSelected={onCardClicked} />;
};

export default PostSelectContainer;
