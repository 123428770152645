import axios from 'axios';

export const API_ORIGIN =
  process.env.NODE_ENV === 'development'
    ? 'https://backend.wim.kro.kr:6003'
    : 'https://backend.wim.kro.kr:6003';

axios.defaults.baseURL = `${API_ORIGIN}/api/v1`;

axios.interceptors.request.use((req) => {
  console.log('[Axios Request]', req);
  return req;
});

axios.interceptors.response.use((res) => {
  console.log('[Axios Response]', res);
  return res;
});

export const apiRoute = {
  health: '/health',
  auth: {
    register: '/register',
    login: '/login',
    logout: '/logout',
    refresh: '/refresh',
    update: '',
  },
  banner: {
    getBanners: '/banners',
    addBanners: '/banners',
    updateBanners: '/banners',
  },
  post: {
    getPost: '/posts',
    addPost: '/posts',
    postDetail: '/posts',
    updatePost: '/posts',
    deletePost: '/posts',
  },
  cardNews: {
    getCards: '/posts/cards',
    updateCards: '/posts/cards',
  },
  admin: {
    getAdmins: '/users',
    registerAdmin: '/register',
  },
  postCategory: {
    getCategory: '/posts/categories',
    addCategory: '/posts/categories',
    updateCategory: '/posts/categories',
    deleteCategory: '/posts/categories',
  },
  calcItem: {
    getCalcItemList: '/inquiry-estimates/sections',
    addCalcItem: '/inquiry-estimates/sections/categories',
    updateCalcItem: '/inquiry-estimates/sections/categories',
    deleteCalcItem: '/inquiry-estimates/sections/categories',
    addSection: '/inquiry-estimates/sections',
    deleteSection: '/inquiry-estimates/sections',
    updateSection: '/inquiry-estimates/sections',
  },
  request: {
    getRequests: '/inquiry-developments',
    updateRequests: '/inquiry-developments',
  },
  qna: {
    getQnas: '/qnas',
    addQna: '/qnas',
    reply: '/qnas',
    deleteQna: '/qnas',
  },
  apply: {
    getApplyList: '/join-applications',
    addApply: '/join-applications',
    getApplyCategoryList: '/join-applications/categories',
    addApplyCategoryList: '/join-applications/categories',
    updateApply: '/join-applications',
  },
  group: {
    getGroupList: '/auth-groups',
    addGroup: '/auth-groups',
  },
  menu: {
    getMenuList: '/menus',
    addMenu: '/menus',
  },
  program: {
    getProgramList: '/programs',
    addProgram: '/programs',
  },
  commonCode: {
    getCommonCodeList: '/common-codes',
    addCommonCode: '/common-codes',
    deleteCommonCode: '/common-codes',
  },
};

export type BasicApiResponse<T> = {
  data: T;
  config: {
    status: number;
  };
};

export type BasicListApiResponse<T> = {
  data: T;
  meta: {
    page: number;
    take: number;
    itemCount: number;
    pageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
};

export function requestGet<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureGet<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestDelete<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipart<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}
