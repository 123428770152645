import React, { useEffect, useState } from 'react';
import CCheckBox from '../CCheckBox';

type Props = {
  label: string;
  onChange: (checked: boolean) => void;
  initialValue?: boolean;
};

const CCheckBoxContainer = ({
  label,
  onChange,
  initialValue = false,
}: Props) => {
  const [checked, setChecked] = useState(initialValue);

  useEffect(() => {
    onChange(checked);
  }, [checked]);

  return <CCheckBox label={label} setChecked={setChecked} checked={checked} />;
};

export default CCheckBoxContainer;
