import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { formatDate, parseDate } from '@lib/factory';
import { MainBannerTypes } from '@typedef/components/MainBanner/main.banner.types';
import React, { RefObject } from 'react';
import images from 'src/assets/images';
import './styles/main.banner.styles.css';

type Props = {
  currentBanner: MainBannerTypes | null;
  banners: MainBannerTypes[];
  onDateSelected: (selectedDate: Date) => void;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  onURLApplyClicked: () => void;
  onPostingClicked: (index: number) => void;
  inputFileRef: RefObject<HTMLInputElement>;
  onFileSelectClicked: () => void;
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  onAddBannerClicked: () => void;
};

const MainBanner = ({
  currentBanner,
  banners,
  onDateSelected,
  setUrl,
  onURLApplyClicked,
  onPostingClicked,
  inputFileRef,
  onFileSelectClicked,
  selectedFile,
  setSelectedFile,
  onAddBannerClicked,
}: Props) => {
  return (
    <div className='main-banner-root'>
      <div className='content-title-section'>
        <span>메인 배너 관리</span>
      </div>
      <div className='preview-section'>
        <div className='image-video-article'>
          <span className='title'>영상 및 이미지</span>
          <div className='image-video'>
            {currentBanner?.extensionKor === '이미지' ? (
              <img
                src={currentBanner.location}
                alt='Current Main Banner Image'
              />
            ) : (
              <video autoPlay loop muted>
                <source src={currentBanner?.location} type='video/mp4' />
              </video>
            )}
          </div>
        </div>
        <div className='url-article'>
          <span className='title'>랜딩 URL</span>
          <div className='url'>
            {currentBanner !== null && (
              <CInputContainer
                onValueChanged={(value) => {
                  setUrl(value);
                }}
                testValue={() => true}
                placeholder='URL을 입력해주세요'
                className='url-input'
                initialState={currentBanner?.referenceUrl}
              />
            )}
            <button onClick={onURLApplyClicked} className='url-btn'>
              적용
            </button>
          </div>
        </div>
      </div>
      <div className='list-section'>
        <div className='file-list'>
          <div className='search-article'>
            <div>
              <span className='title'>등록일</span>
              <CInputContainer
                onValueChanged={(val, isValid) => {
                  if (isValid) {
                    onDateSelected(parseDate(val));
                  }
                }}
                testValue={(val) => {
                  return val !== '';
                }}
                placeholder='등록일을 입력해주세요'
                type='date'
                className='date-input'
              />
            </div>
            <div>
              <CInputContainer
                onValueChanged={() => {}}
                testValue={() => true}
                placeholder='파일명을 입력해주세요'
                className='file-name-input'
              />
              <button className='search-btn'>검색</button>
            </div>
          </div>
          <div className='list-article'>
            <div className='list-header'>
              <span className='list-header-item'>파일명</span>
              <span className='list-header-item'>등록일</span>
              <span className='list-header-item'>등록인</span>
              <span className='list-header-item'>파일 형식</span>
              <span className='list-header-item'>게시 여부</span>
            </div>
            <div className='list-body'>
              {banners.map((item, index) => (
                <div key={index} className='list-item'>
                  <span className='list-item-label'>{item.originalName}</span>
                  <span className='list-item-label'>
                    {formatDate(new Date(item.createdAt))}
                  </span>
                  <span className='list-item-label'>{item.createdBy}</span>
                  <span className='list-item-label'>{item.extensionKor}</span>
                  <span
                    className='list-item-label'
                    onClick={() => onPostingClicked(index)}>
                    <img
                      src={
                        item.isPosted
                          ? images.mainbanner.check_box_on
                          : images.mainbanner.check_box_off
                      }
                      alt='Check Box'
                    />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='file-input'>
          <div className='file-name input-item'>
            <span className='title'>파일명</span>
            <CInputContainer
              onValueChanged={() => {}}
              testValue={() => true}
              className='input'
              disabled
              placeholder='파일을 선택해주세요'
              initialState={selectedFile?.name?.split('\\').pop() || ''}
            />
          </div>
          <div className='file-type input-item'>
            <span className='title'>파일 형식</span>
            <CInputContainer
              onValueChanged={() => {}}
              testValue={() => true}
              className='input'
              disabled
              placeholder='파일을 선택해주세요'
              initialState={
                selectedFile === null
                  ? ''
                  : selectedFile.name.endsWith('.mp4')
                  ? '영상'
                  : '이미지' || ''
              }
            />
          </div>
          <input
            ref={inputFileRef}
            style={{ display: 'none' }}
            onChange={(e) => {
              console.log(e);
              setSelectedFile(e.target.files?.item(0) || null);
            }}
            type='file'
            accept='.png,.jpg,.mp4'
          />
          <button onClick={onFileSelectClicked} className='input-btn'>
            파일 선택
          </button>
          <button onClick={onAddBannerClicked} className='input-btn'>
            파일 등록
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
