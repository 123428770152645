import { HIDE_POPUP, SHOW_POPUP } from './modules/actionTypes';

export const showPopup = (child: React.ReactNode) => ({
  type: SHOW_POPUP,
  payload: child,
});

export const hidePopup = () => ({
  type: HIDE_POPUP,
  payload: {},
});

export type PopupActionTypes =
  | ReturnType<typeof showPopup>
  | ReturnType<typeof hidePopup>;
