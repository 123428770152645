import { MenuItemType } from '@typedef/components/Menu/menu.types';
import { MenuListActionTypes } from './actions';
import {
  ADD_MENU,
  CLEAR_MENU_LIST,
  UPDATE_MENU_LIST,
} from './modules/actionTypes';

export type MenuListStateType = {
  list: MenuItemType[];
};

const initialState: MenuListStateType = {
  list: [],
};

const menuListReducer = (
  prev: MenuListStateType = initialState,
  { type, payload }: MenuListActionTypes,
): MenuListStateType => {
  switch (type) {
    case UPDATE_MENU_LIST:
      return {
        list: payload as MenuItemType[],
      };
    case CLEAR_MENU_LIST:
      return initialState;
    case ADD_MENU:
      return {
        list: [...prev.list, payload as MenuItemType],
      };
    default:
      return prev;
  }
};

export default menuListReducer;
