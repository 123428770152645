import images from '@assets/images';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { SignUpFormType } from '@typedef/components/Admin/admin.types';
import { GroupItemTypes } from '@typedef/components/Group/group.types';
import React from 'react';
import '../style/admin.create.styles.css';

type Props = {
  onInputChanged: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  path: string | undefined;
  signUpForm: SignUpFormType;
  onCancelClicked: () => void;
  onSubmit: (e: React.FormEvent) => void;
  onProfileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: GroupItemTypes[];
  setSelectedGroup: React.Dispatch<React.SetStateAction<GroupItemTypes | null>>;
};

const AdminCreate = ({
  onInputChanged,
  path,
  signUpForm,
  onCancelClicked,
  onSubmit,
  onProfileChange,
  options,
  setSelectedGroup,
}: Props) => {
  return (
    <div className='admin-create-root'>
      <div className='title-section'>
        <span className='title'>어드민 관리</span>
        <div className='button-wrapper'>
          <button className='cancel-button' onClick={onCancelClicked}>
            취소하기
          </button>
        </div>
      </div>
      <div className='content-section'>
        <form
          className='admin-form'
          encType='multipart/form-data'
          onSubmit={onSubmit}>
          <div className='input-section'>
            <div className='input-item'>
              <div className='input-label'>닉네임</div>
              <input
                className='input'
                placeholder='홍길동'
                value={signUpForm.displayName}
                onChange={(e) => onInputChanged(e, 'displayName')}
                required
              />
            </div>
            <div className='input-item'>
              <div className='input-label'>아이디</div>
              <input
                className='input'
                placeholder='jdh9797'
                value={signUpForm.userName}
                onChange={(e) => onInputChanged(e, 'userName')}
                required
              />
            </div>
            <div className='input-item'>
              <div className='input-label'>비밀번호</div>
              <input
                className='input'
                placeholder='Pw123456'
                value={signUpForm.password}
                onChange={(e) => onInputChanged(e, 'password')}
              />
            </div>
            <div className='input-item'>
              <div className='input-label'>이메일</div>
              <input
                className='input'
                placeholder='example@example.com'
                value={signUpForm.email}
                onChange={(e) => onInputChanged(e, 'email')}
                required
              />
            </div>
            <div className='input-item'>
              <div className='input-label'>연락처</div>
              <input
                className='input'
                placeholder='010-0000-0000'
                value={signUpForm.phone}
                onChange={(e) => onInputChanged(e, 'phone')}
                required
              />
            </div>
          </div>
          <div className='profile-section'>
            <div className='input-item'>
              <div className='input-label'>프로필</div>
              <div className='profile-wrapper'>
                <img src={path ? path : images.admin.profile_placeholder} />
                <input
                  id='profile-input'
                  type='file'
                  accept='image/*'
                  onChange={onProfileChange}
                />
                <label htmlFor='profile-input'>업로드</label>
              </div>
            </div>
            <div className='input-item'>
              <div className='input-label'>역할</div>
              <CSelectContainer
                options={options}
                onSelected={setSelectedGroup}
                initialSelected='권한 그룹을 선택해주세요'
                labelSelector={(item) => item.name}
              />
            </div>
            <button type='submit' className='store-button'>
              저장하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminCreate;
