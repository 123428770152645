import CCheckBoxContainer from '@components/common/CCheckBox/containers/CCheckBoxContainer';
import { ApplyItemType } from '@typedef/components/Apply/apply.types';
import React from 'react';
import '../styles/apply.detail.styles.css';

type Props = {
  data: ApplyItemType;
  onConfirmCheckChanged: (checked: boolean) => void;
};

const ApplyDetail = ({ data, onConfirmCheckChanged }: Props) => {
  return (
    <div className='apply-detail-root'>
      <div className='info-section'>
        <div className='title'>이름</div>
        <div className='content'>{data.name}</div>
      </div>
      <div className='info-section'>
        <div className='title'>연락처</div>
        <div className='content'>{data.phone}</div>
      </div>
      <div className='info-section'>
        <div className='title'>이메일</div>
        <div className='content'>{data.email}</div>
      </div>
      <div className='info-section'>
        <div className='title'>이력서</div>
        <a href={data.location} target='_blank' className='content'>
          {data.location}
        </a>
      </div>
      <div className='info-section'>
        <div className='title'>링크</div>
        <a href={data.referenceUrl} className='content'>
          {data.referenceUrl}
        </a>
      </div>
      <CCheckBoxContainer
        label='검토 여부'
        initialValue={data.confirmedAt !== null}
        onChange={onConfirmCheckChanged}
      />
    </div>
  );
};

export default ApplyDetail;
