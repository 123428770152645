import useGroup from '@hooks/group/useGroup';
import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, requestSecurePost } from '@lib/api';
import { CommonCodeTypes } from '@typedef/components/CommonCode/commoncode.types';
import { GroupItemTypes } from '@typedef/components/Group/group.types';
import React, { useCallback, useState } from 'react';
import GroupAddPopup from '../components/GroupAddPopup';

const GroupAddPopupContainer = () => {
  const { getAccessToken } = useToken();

  const { __hidePopupFromHooks } = usePopup();

  const { __addGroupFromHooks } = useGroup();

  const [groupName, setGroupName] = useState('');
  const [groupUid, setGroupUid] = useState('');
  const [remark, setRemark] = useState('');

  const onCancelBtnClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  const onAddBtnClicked = useCallback(async () => {
    const { data: commonCode, config } =
      await requestSecurePost<CommonCodeTypes>(
        apiRoute.commonCode.addCommonCode,
        {},
        {
          code: groupUid,
          groupName: 'AUTH_GROUP_CODE_GROUP',
          name: groupName,
          sort: 0,
          remark,
        },
        getAccessToken(),
      );

    if (config.status === 201) {
      const { data, config } = await requestSecurePost<GroupItemTypes>(
        apiRoute.group.addGroup,
        {},
        {
          code: commonCode.code,
          name: groupName,
        },
        getAccessToken(),
      );

      if (config.status === 201) {
        __addGroupFromHooks(data);
        __hidePopupFromHooks();
      } else {
        alertMessage('권한 그룹 생성에 실패했습니다');
      }
    } else {
      alertMessage('공통코드 생성에 실패했습니다');
    }
  }, [
    groupName,
    groupUid,
    remark,
    getAccessToken,
    __hidePopupFromHooks,
    __addGroupFromHooks,
  ]);

  return (
    <GroupAddPopup
      setGroupName={setGroupName}
      setGroupUid={setGroupUid}
      setRemark={setRemark}
      onCancelBtnClicked={onCancelBtnClicked}
      onAddBtnClicked={onAddBtnClicked}
    />
  );
};

export default GroupAddPopupContainer;
