import { GroupItemTypes } from '@typedef/components/Group/group.types';
import { GroupListActionTypes } from './actions';
import {
  ADD_GROUP,
  CLEAR_GROUP_LIST,
  UPDATE_GROUP_LIST,
} from './modules/actionTypes';

export type GroupListStateType = {
  list: GroupItemTypes[];
};

const initialState: GroupListStateType = {
  list: [],
};

const groupListReducer = (
  prev: GroupListStateType = initialState,
  { type, payload }: GroupListActionTypes,
): GroupListStateType => {
  switch (type) {
    case UPDATE_GROUP_LIST:
      return {
        list: payload as GroupItemTypes[],
      };
    case CLEAR_GROUP_LIST:
      return initialState;
    case ADD_GROUP:
      return {
        list: [...prev.list, payload as GroupItemTypes],
      };
    default:
      return prev;
  }
};

export default groupListReducer;
