import images from '@assets/images';
import { QnaType } from '@typedef/components/Qna/qna.types';
import React from 'react';
import '../styles/qna.detail.popup.style.css';

type Props = {
  item: QnaType;
  onCheckClicked: () => void;
  isFaq: boolean;
  replyRef: React.RefObject<HTMLTextAreaElement>;
  onCancelClicked: () => void;
  onStoreClicked: () => void;
  onDeleteClicked: () => void;
};

const QnaDetailPopup = ({
  item,
  onCancelClicked,
  onCheckClicked,
  onStoreClicked,
  onDeleteClicked,
  isFaq,
  replyRef,
}: Props) => {
  return (
    <div className='qna-detail-root' onClick={(e) => e.stopPropagation()}>
      <button className='delete-button' onClick={onDeleteClicked}>
        삭제
      </button>
      <div className='title-section'>
        <div className='section-label'>제목</div>
        <div className='title-content'>{item.title}</div>
      </div>
      <div className='content-section'>
        <div className='section-label'>내용</div>
        <div className='content-content'>{item.contents}</div>
      </div>
      <div className='reply-section'>
        <div className='section-label'>답변</div>
        <textarea
          className='reply-input'
          defaultValue={item.reply}
          ref={replyRef}
        />
      </div>
      <div className='check-box-wrapper' onClick={onCheckClicked}>
        <img
          src={isFaq ? images.admin.selected : images.admin.unslected}
          alt='ic_check'
        />
        <span className='label'>FAQ 채택여부</span>
      </div>
      <div className='button-wrapper'>
        <button onClick={onCancelClicked}>취소하기</button>
        <button onClick={onStoreClicked}>저장하기</button>
      </div>
    </div>
  );
};

export default QnaDetailPopup;
