import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, BasicListApiResponse, requestSecureGet } from '@lib/api';
import { CommonCodeTypes } from '@typedef/components/CommonCode/commoncode.types';
import React, { useCallback, useEffect, useState } from 'react';
import CommonCode from '../CommonCode';

const CommonCodeContainer = () => {
  const { getAccessToken } = useToken();

  const [commonCodeList, setCommonCodeList] = useState<CommonCodeTypes[]>([]);

  const loadCommonCode = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<CommonCodeTypes[]>
    >(apiRoute.commonCode.getCommonCodeList, {}, getAccessToken());

    if (config.status === 200) {
      setCommonCodeList(data.data);
    } else {
      alertMessage('데이터를 불러오지 못했습니다');
    }
  }, []);

  const onAddBtnClicked = useCallback(() => {}, []);

  const onListItemClicked = useCallback((item: CommonCodeTypes) => {}, []);

  useEffect(() => {
    loadCommonCode();
  }, []);

  return (
    <CommonCode
      commonCodeList={commonCodeList}
      onAddBtnClicked={onAddBtnClicked}
      onListItemClicked={onListItemClicked}
    />
  );
};

export default CommonCodeContainer;
