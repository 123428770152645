import React, { useCallback, useMemo, useRef, useState } from 'react';
import CSearch from '../CSearch';

type Props = {
  onSearchClicked: (keyword: string) => void;
  placeholder: string;
  className?: string;
};

const CSearchContainer = ({
  onSearchClicked,
  placeholder,
  className = '',
}: Props) => {
  const [keyword, setKeyword] = useState('');

  const deleteBtnVisible = useMemo(() => {
    console.log(keyword !== '');

    return keyword !== '';
  }, [keyword]);

  const inputRef = useRef<HTMLInputElement>(null);

  const onSearchBtnClicked = useCallback(() => {
    onSearchClicked(keyword);
  }, [keyword]);

  const onDeleteBtnClicked = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
      setKeyword('');
    }
  }, [inputRef]);

  return (
    <CSearch
      onSearchBtnClicked={onSearchBtnClicked}
      placeholder={placeholder}
      inputRef={inputRef}
      onDeleteBtnClicked={onDeleteBtnClicked}
      deleteBtnVisible={deleteBtnVisible}
      setKeyword={setKeyword}
      className={className}
    />
  );
};

export default CSearchContainer;
