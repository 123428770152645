import { QnaType } from '@typedef/components/Qna/qna.types';
import { RESET_QNA, UPDATE_QNA } from './modules/actionTypes';

export const updateQnaList = (payload: QnaType[]) => ({
  type: UPDATE_QNA,
  payload: payload,
});

export const resetQnaList = () => ({
  type: RESET_QNA,
  payload: [],
});

export type QnaActionType =
  | ReturnType<typeof updateQnaList>
  | ReturnType<typeof resetQnaList>;
