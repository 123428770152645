import { QnaType } from '@typedef/components/Qna/qna.types';
import { QnaActionType } from './actions';
import { RESET_QNA, UPDATE_QNA } from './modules/actionTypes';

export type QnaListStateType = {
  list: QnaType[];
};

const initialState = {
  list: [],
};

export default function qnaReducer(
  state: QnaListStateType = initialState,
  { type, payload }: QnaActionType,
): QnaListStateType {
  switch (type) {
    case UPDATE_QNA:
      return {
        list: payload,
      };
    case RESET_QNA:
      return {
        list: [],
      };
    default:
      return state;
  }
}
