import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import HomeNavigationContainer from './containers/HomeNavigationContainer';
import LoginNavigationContainer from './containers/LoginNavigationContainer';
import PopupContainer from './containers/PopupContainer';

type Props = {
  root: 'login' | 'main';
};

const RootNavigation = ({ root }: Props) => {
  return (
    <BrowserRouter>
      {root === 'login' ? (
        <LoginNavigationContainer />
      ) : (
        <HomeNavigationContainer />
      )}
      <PopupContainer />
    </BrowserRouter>
  );
};

export default RootNavigation;
