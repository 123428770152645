import { SectionItemTypes } from '@typedef/components/CalcItem/calc.item.types';
import React from 'react';
import SectionItemContainer from './containers/SectionItemContainer';
import './styles/calc.item.styles.css';

type Props = {
  sectionList: SectionItemTypes[];
  onSectionDeleted: (data: SectionItemTypes) => void;
  setSectionName: React.Dispatch<React.SetStateAction<string>>;
  onSectionAddClicked: () => void;
};

const CalcItem = ({
  sectionList,
  onSectionDeleted,
  setSectionName,
  onSectionAddClicked,
}: Props) => {
  return (
    <div className='calc-item-root'>
      <div className='intro-section'>
        <div className='title'>안내문구</div>
        <div className='content'>
          {`* 예상 견적은 고객사가 구현하고자 하는 기능들을 알아보기 위한 수단입니다.

* 개발문의 후 정확한 견적 산출을 위해 도움을 드리니, 희망하시는 기능을 추가해보세요.

* 자바스크립트로 구축하며 Vercel, Firebase, Supabase 등 최적의 클라우드를 선택합니다`}
        </div>
      </div>
      <div className='item-list-section'>
        {sectionList.map((item, index) => (
          <SectionItemContainer
            key={index}
            data={item}
            onSectionDeleted={onSectionDeleted}
          />
        ))}
        <div onClick={onSectionAddClicked} className='section-add-btn'>
          <input
            onClick={(e) => {
              e.stopPropagation();
            }}
            type='text'
            onChange={(e) => {
              setSectionName(e.target.value);
            }}
            placeholder='이름을 입력해주세요'
          />
          <span>새 섹션 추가 +</span>
        </div>
      </div>
    </div>
  );
};

export default CalcItem;
