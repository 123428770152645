import { formatDate } from '@lib/factory';
import {
  GetCardNewsResponseTypes,
  PostTypes,
} from '@typedef/components/CardNews/card.news.types';
import React from 'react';
import MainNewsCardContainer from './containers/MainNewsCardContainer';
import PortfolioCardContainer from './containers/PortfolioCardContainer';
import SubNewsCardContainer from './containers/SubNewsCardContainer';
import './styles/card.news.styles.css';

type Props = {
  cards: GetCardNewsResponseTypes;
  onCardClicked: (separator: 'MAIN' | 'SUB', post?: PostTypes) => void;
};

const CardNews = ({ cards, onCardClicked }: Props) => {
  return (
    <div className='card-news-section'>
      <div className='title-article'>
        <span className='title'>{formatDate(new Date())} 오늘의 WIM 소식</span>
        <span className='sub-title'>매일 Wim이 전하는 소식들을 만나보세요</span>
      </div>
      <div className='card-news-row'>
        <div className='card-news-column'>
          <SubNewsCardContainer
            post={cards.sub[0]}
            onClick={() => onCardClicked('SUB', cards.sub[0])}
          />
          <SubNewsCardContainer
            post={cards.sub[1]}
            onClick={() => onCardClicked('SUB', cards.sub[1])}
          />
        </div>
        <div className='card-news-column main'>
          <MainNewsCardContainer
            post={cards.main[0]}
            subTitleShow={false}
            onClick={() => onCardClicked('MAIN', cards.main[0])}
          />
          <MainNewsCardContainer
            post={cards.main[1]}
            subTitleShow={false}
            onClick={() => onCardClicked('MAIN', cards.main[1])}
          />
          <MainNewsCardContainer
            post={cards.main[2]}
            subTitleShow={false}
            onClick={() => onCardClicked('MAIN', cards.main[2])}
          />
        </div>
        <div className='card-news-column'>
          <PortfolioCardContainer />
          <SubNewsCardContainer
            post={cards.sub[2]}
            onClick={() => onCardClicked('SUB', cards.sub[2])}
          />
        </div>
      </div>
    </div>
  );
};

export default CardNews;
