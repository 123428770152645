import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, requestGet, requestSecurePatch } from '@lib/api';
import { GetCardNewsResponseTypes } from '@typedef/components/CardNews/card.news.types';
import React, { useCallback, useEffect, useState } from 'react';
import CardNews from '../CardNews';
import PostSelectContainer from './PostSelectContainer';
import { PostTypes } from '@typedef/components/CardNews/card.news.types';

const CardNewsContainer = () => {
  const { __showPopupFromHooks, __hidePopupFromHooks } = usePopup();
  const { getAccessToken } = useToken();

  const [cards, setCards] = useState<GetCardNewsResponseTypes>({
    main: [],
    sub: [],
  });

  const [loadDone, setLoadDone] = useState(false);

  const [clickedPost, setClickedPost] = useState<string | null>(null);

  const loadCards = useCallback(async () => {
    const { data, config } = await requestGet<GetCardNewsResponseTypes>(
      apiRoute.cardNews.getCards,
      {},
    );

    if (config.status === 200) {
      setCards(data);
    } else {
      alertMessage('카드 목록을 불러오지 못했습니다');
    }

    setLoadDone(true);
  }, []);

  const onChangeSelected = useCallback(
    async (selectedId: string, separator: 'MAIN' | 'SUB') => {
      const { data, config } = await requestSecurePatch<PostTypes>(
        apiRoute.cardNews.updateCards,
        {},
        {
          prevId: clickedPost || undefined,
          nextId: selectedId,
          separator,
        },
        getAccessToken(),
      );

      if (config.status === 201 || config.status === 202) {
        loadCards();
        __hidePopupFromHooks();
      } else {
        alertMessage('카드뉴스 변경에 실패했습니다');
      }
    },
    [clickedPost, getAccessToken, loadCards, __hidePopupFromHooks],
  );

  const onCardClicked = useCallback(
    (separator: 'MAIN' | 'SUB', post?: PostTypes) => {
      setClickedPost(post?.id ?? null);

      __showPopupFromHooks(
        <PostSelectContainer
          separator={separator}
          onChangeSelected={onChangeSelected}
        />,
      );
    },
    [__showPopupFromHooks, onChangeSelected],
  );

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  return loadDone ? (
    <CardNews cards={cards} onCardClicked={onCardClicked} />
  ) : null;
};

export default CardNewsContainer;
