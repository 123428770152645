import usePopup from '@hooks/popup/usePopup';
import usePrograms from '@hooks/store/usePrograms';
import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, requestSecurePost } from '@lib/api';
import { CommonCodeTypes } from '@typedef/components/CommonCode/commoncode.types';
import { ProgramTypes } from '@typedef/components/Program/program.types';
import React, { useCallback, useState } from 'react';
import ProgramAddPopup from '../components/ProgramAddPopup';

const ProgramAddPopupContainer = () => {
  const { getAccessToken } = useToken();

  const { __hidePopupFromHooks } = usePopup();

  const { __addProgramFromHooks } = usePrograms();

  const [programUid, setProgramUid] = useState('');

  const [programName, setProgramName] = useState('');

  const [url, setUrl] = useState('');

  const [remark, setRemark] = useState('');

  const onAddBtnClicked = useCallback(async () => {
    const { data, config } = await requestSecurePost<CommonCodeTypes>(
      apiRoute.commonCode.addCommonCode,
      {},
      {
        code: programUid,
        groupName: 'PROGRAM_CODE_GROUP',
        name: programName,
        sort: 0,
        remark,
      },
      getAccessToken(),
    );

    if (config.status === 201) {
      const { data: program, config } = await requestSecurePost<ProgramTypes>(
        apiRoute.program.addProgram,
        {},
        {
          code: data.code,
          name: programName,
          path: url,
          remark: data.remark,
        },
        getAccessToken(),
      );

      if (config.status === 201) {
        __addProgramFromHooks(program);
        __hidePopupFromHooks();
      } else {
        alertMessage('프로그램 등록에 실패했습니다');
      }
    } else {
      alertMessage('공통코드 등록에 실패했습니다');
    }
  }, [
    programUid,
    programName,
    remark,
    url,
    getAccessToken,
    __addProgramFromHooks,
    __hidePopupFromHooks,
  ]);

  const onCancelBtnClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  return (
    <ProgramAddPopup
      setProgramName={setProgramName}
      setProgramUid={setProgramUid}
      setUrl={setUrl}
      setRemark={setRemark}
      onAddBtnClicked={onAddBtnClicked}
      onCancelBtnClicked={onCancelBtnClicked}
    />
  );
};

export default ProgramAddPopupContainer;
