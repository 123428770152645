import images from '@assets/images';
import { PostTypes } from '@typedef/components/CardNews/card.news.types';
import React, { useEffect, useRef } from 'react';
import SubNewsCard from '../components/SubNewsCard';

type Props = {
  post?: PostTypes;
  onClick?: () => void;
};

const SubNewsCardContainer = ({ post, onClick }: Props) => {
  const cardRootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRootRef?.current) {
      cardRootRef.current.style.background = `url(${
        post ? post.postFile[0]?.location : images.cardnews.dummy
      }) no-repeat`;
      cardRootRef.current.style.backgroundSize = 'cover';
      cardRootRef.current.style.backgroundPosition = '0px -1px';
    }
  }, [post, cardRootRef]);

  return (
    <SubNewsCard cardRootRef={cardRootRef} post={post} onClick={onClick} />
  );
};

export default SubNewsCardContainer;
