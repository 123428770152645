import React, {
  HTMLInputTypeAttribute,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CInput from '../CInput';

type Props = {
  onValueChanged: (val: string, isValid: boolean) => void;
  testValue: (val: string) => boolean;
  initialState?: string;
  className?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
};

const CInputContainer = ({
  onValueChanged,
  testValue,
  initialState = '',
  className = '',
  placeholder = '입력해주세요',
  type = 'text',
  disabled = false,
}: Props) => {
  const [input, setInput] = useState(initialState);

  const isValid = useMemo(() => {
    return testValue(input);
  }, [input]);

  const [isFocusing, setIsFocusing] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current) {
      if (isFocusing) {
        inputRef.current.style.borderColor = '#2527ff';
      } else if (!isValid) {
        inputRef.current.style.borderColor = '#dc0000';
      } else {
        inputRef.current.style.borderColor = '#e1e1e1';
      }
    }
  }, [isValid, isFocusing, inputRef]);

  useEffect(() => {
    if (inputRef?.current) {
      if (disabled) {
        inputRef.current.style.backgroundColor = '#dedede';
        inputRef.current.style.borderColor = '#707070';
      } else {
        inputRef.current.style.backgroundColor = '#ffffff';
        inputRef.current.style.borderColor = '#e1e1e1';
      }
    }
  }, [inputRef, disabled]);

  useEffect(() => {
    onValueChanged(input, isValid);
  }, [input, isValid]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = initialState;
    }
  }, [inputRef, initialState]);

  useEffect(() => {
    if (inputRef?.current) {
      if (type === 'file') {
        inputRef.current.style.display = 'none';
      }
    }
  }, [inputRef, type]);

  return (
    <CInput
      setInput={setInput}
      inputRef={inputRef}
      setIsFocusing={setIsFocusing}
      className={className}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
  );
};

export default CInputContainer;
