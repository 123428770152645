import useQnaList from '@hooks/component/qna/useQnaList';
import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import {
  apiRoute,
  requestSecureDelete,
  requestSecurePatch,
  requestSecurePost,
} from '@lib/api';
import { QnaType } from '@typedef/components/Qna/qna.types';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QnaDetailPopup from '../components/QnaDetailPopup';

type Props = {
  item: QnaType;
};

const QnaDetailPopupContainer = ({ item }: Props) => {
  const { __hidePopupFromHooks } = usePopup();
  const { getAccessToken } = useToken();
  const { qnaList, __updateQnaList } = useQnaList();

  const [isFaq, setIsFaq] = useState<boolean>(item.isFaq === 1 ? true : false);
  const replyRef = useRef<HTMLTextAreaElement>(null);

  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  const onCheckClicked = useCallback(() => {
    setIsFaq((prev) => !prev);
  }, [isFaq]);

  const onStoreClicked = useCallback(async () => {
    const token = getAccessToken();
    const {
      data,
      config: { status },
    } = await requestSecurePatch<QnaType>(
      apiRoute.qna.reply + `/${item.id}`,
      {},
      { reply: replyRef.current?.value, isFaq: isFaq ? 1 : 0 },
      token,
    );

    if (status !== 202) {
      console.error('reply failed');
      return;
    }

    console.log(data);
    __updateQnaList(
      qnaList.map((i, index) => {
        if (i.id === item.id) {
          return data;
        } else {
          return i;
        }
      }),
    );
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks, replyRef, isFaq]);

  const onDeleteClicked = useCallback(async () => {
    const token = getAccessToken();
    const {
      config: { status },
    } = await requestSecureDelete<Object>(
      apiRoute.qna.reply + `/${item.id}`,
      {},
      token,
    );

    if (status !== 202) {
      console.error('qna delete failed');
      return;
    }

    __updateQnaList(qnaList.filter((i) => i.id !== item.id));
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  return (
    <QnaDetailPopup
      item={item}
      onCancelClicked={onCancelClicked}
      onCheckClicked={onCheckClicked}
      onStoreClicked={onStoreClicked}
      isFaq={isFaq}
      replyRef={replyRef}
      onDeleteClicked={onDeleteClicked}
    />
  );
};

export default QnaDetailPopupContainer;
