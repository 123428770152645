import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import { ApplyItemType } from '@typedef/components/Apply/apply.types';
import React from 'react';
import './styles/apply.styles.css';

type Props = {
  onSearchClicked: () => void;
  applyList: ApplyItemType[];
  onListItemClicked: (index: number) => void;
};

const Apply = ({ onSearchClicked, applyList, onListItemClicked }: Props) => {
  return (
    <div className='apply-root'>
      <div className='search-section'>
        <CSearchContainer
          onSearchClicked={onSearchClicked}
          placeholder='이름을 입력해주세요'
          className='name-input'
        />
      </div>
      <div className='list-section'>
        <div className='header-article'>
          <span className='header-item'>직무</span>
          <span className='header-item'>이름</span>
          <span className='header-item'>이메일</span>
          <span className='header-item'>전화번호</span>
          <span className='header-item'>검토여부</span>
        </div>
        <div className='body-article'>
          {applyList.map((item, index) => (
            <div
              onClick={() => onListItemClicked(index)}
              key={index}
              className='list-item'>
              <span className='item-label'>{item.categoryName}</span>
              <span className='item-label'>{item.name}</span>
              <span className='item-label'>{item.email}</span>
              <span className='item-label'>{item.phone}</span>
              <span className='item-label'>
                {item.confirmedAt ? '검토' : '미검토'}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Apply;
