import { MenuItemType } from '@typedef/components/Menu/menu.types';
import React from 'react';
import './styles/menu.styles.css';

type Props = {
  menu: MenuItemType[];
  onAddBtnClicked: () => void;
};

const Menu = ({ menu, onAddBtnClicked }: Props) => {
  return (
    <div className='menu-root'>
      <div className='header-section'>
        <div className='title'>메뉴 관리</div>
        <button onClick={onAddBtnClicked} className='add-btn'>
          추가하기
        </button>
      </div>
      <div className='content-section'>
        <div className='list-section'>
          <div className='header-article'>
            <span className='header-item'>메뉴 이름</span>
            <span className='header-item'>프로그램 UID</span>
            <span className='header-item'>프로그램 이름</span>
            <span className='header-item'>프로그램 경로</span>
            <span className='header-item'>권한그룹 UID</span>
            <span className='header-item'>권힌그룹 이름</span>
          </div>
          <div className='body-article'>
            {menu.map((item, index) => (
              <div key={index} className='list-item'>
                <span className='item-label'>{item.name}</span>
                <span className='item-label'>{item.program.code}</span>
                <span className='item-label'>{item.program.name}</span>
                <span className='item-label'>{item.program.path}</span>
                <span className='item-label'>{item.authGroup.code}</span>
                <span className='item-label'>{item.authGroup.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
