import useQnaList from '@hooks/component/qna/useQnaList';
import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import { apiRoute, requestSecurePost } from '@lib/api';
import { AddQnaType, QnaType } from '@typedef/components/Qna/qna.types';
import React, { useCallback, useState } from 'react';
import QnaCreatePopup from '../components/QnaCreatePopup';

const QnaCreatePopupContainer = () => {
  const { getAccessToken } = useToken();
  const { __hidePopupFromHooks } = usePopup();
  const { qnaList, __updateQnaList } = useQnaList();

  const [qna, setQna] = useState<AddQnaType>({
    title: '',
    contents: '',
  });

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
      setQna((prev) => ({ ...prev, [key]: e.target.value }));
    },
    [qna],
  );

  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  const onStoreClicked = useCallback(async () => {
    const token = getAccessToken();

    const {
      data,
      config: { status },
    } = await requestSecurePost<QnaType>(
      apiRoute.qna.addQna,
      {},
      {
        qnaCategoryId: '1',
        ...qna,
      },
      token,
    );

    if (status !== 201) {
      console.error('register qna failed');
      return;
    }

    __updateQnaList([data, ...qnaList]);
    __hidePopupFromHooks();
  }, [qna, __hidePopupFromHooks]);

  return (
    <QnaCreatePopup
      onCancelClicked={onCancelClicked}
      onStoreClicked={onStoreClicked}
      onChangeHandler={onChangeHandler}
    />
  );
};

export default QnaCreatePopupContainer;
