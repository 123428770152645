import AdminContainer from '@components/Admin/containers/AdminContainer';
import AdminCreateContainer from '@components/Admin/containers/AdminCreateContainer';
import ApplyContainer from '@components/Apply/containers/ApplyContainer';
import ApplyDetailContainer from '@components/Apply/containers/ApplyDetailContainer';
import CalcItemContainer from '@components/CalcItem/containers/CalcItemContainer';
import CardNewsContainer from '@components/CardNews/containers/CardNewsContainer';
import GNBContainer from '@components/common/GNB/containers/GNBContainer';
import CommonCodeContainer from '@components/CommonCode/containers/CommonCodeContainer';
import GroupContainer from '@components/Group/containers/GroupContainer';
import MainBannerContainer from '@components/MainBanner/containers/MainBannerContainer';
import MenuContainer from '@components/Menu/containers/MenuContainer';
import NotFoundContainer from '@components/NotFound/containers/NotFoundContainer';
import PostContainer from '@components/Post/containers/PostContainer';
import ProgramContainer from '@components/Program/containers/ProgramContainer';
import QnaContainer from '@components/Qna/containers/QnaContainer';
import RequestContainer from '@components/Request/containers/RequestContainer';
import RequestDetailContainer from '@components/Request/containers/RequestDetailContainer';
import { ProgramTypes } from '@typedef/components/Program/program.types';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import '../styles/home.navigation.styles.css';

type Props = {
  userProgram: ProgramTypes[];
};

const HomeNavigation = ({ userProgram }: Props) => {
  return (
    <div className='home-navigation-root'>
      <GNBContainer />
      <Routes>
        <Route path='/mainbanner' element={<MainBannerContainer />} />
        <Route path='/cardnews' element={<CardNewsContainer />} />
        <Route path='/admin' element={<AdminContainer />} />
        <Route path='/admin/create' element={<AdminCreateContainer />} />
        <Route path='/qna' element={<QnaContainer />} />
        <Route path='/post' element={<PostContainer />} />
        <Route path='/calcitem' element={<CalcItemContainer />} />
        <Route>
          <Route path='/request' element={<RequestContainer />} />
          <Route path='/request/detail' element={<RequestDetailContainer />} />
        </Route>
        <Route>
          <Route path='/apply' element={<ApplyContainer />} />
          <Route path='/apply/detail' element={<ApplyDetailContainer />} />
        </Route>
        <Route>
          <Route path='/group' element={<GroupContainer />} />
        </Route>
        <Route>
          <Route path='/menu' element={<MenuContainer />} />
        </Route>
        <Route>
          <Route path='/program' element={<ProgramContainer />} />
        </Route>
        <Route>
          <Route path='/commoncode' element={<CommonCodeContainer />} />
        </Route>
        <Route path='*' element={<NotFoundContainer />} />
      </Routes>
    </div>
  );
};

export default HomeNavigation;
