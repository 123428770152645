import alertMessage from '@lib/alert';
import { apiRoute, BasicListApiResponse, requestGet } from '@lib/api';
import {
  PostCategoryTypes,
  PostTypes,
} from '@typedef/components/CardNews/card.news.types';
import React, { useCallback, useEffect, useState } from 'react';
import Post from '../Post';

const PostContainer = () => {
  const [bigCat, setBigCat] = useState<string | undefined>();

  const [smallCat, setSmallCat] = useState<PostCategoryTypes | undefined>();

  const [smallOptions, setSmallOptions] = useState<PostCategoryTypes[]>([]);

  const [posts, setPosts] = useState<PostTypes[]>([]);

  const loadSmallOptions = useCallback(async () => {
    if (!bigCat) {
      return;
    }

    const { data, config } = await requestGet<
      BasicListApiResponse<PostCategoryTypes[]>
    >(
      `${apiRoute.postCategory.getCategory}?qColumnNames=separator&q=${bigCat}`,
      {},
    );

    if (config.status === 200) {
      setSmallOptions(data.data);
    } else {
      alertMessage('카테고리를 불러오지 못했습니다');
    }
  }, [bigCat]);

  const loadPost = useCallback(async () => {
    const { data, config } = await requestGet<
      BasicListApiResponse<PostTypes[]>
    >(
      `${apiRoute.post.getPost}?${
        smallCat ? 'postCategoryId=' + smallCat.id : ''
      }`,
      {},
    );

    if (config.status === 200) {
      setPosts(data.data);
    } else {
      alertMessage('게시글을 불러오지 못했습니다');
    }
  }, [bigCat, smallCat]);

  useEffect(() => {
    loadPost();
  }, [loadPost]);

  useEffect(() => {
    loadSmallOptions();
  }, [loadSmallOptions]);

  return (
    <Post
      setBigCat={setBigCat}
      setSmallCat={setSmallCat}
      smallOptions={smallOptions}
      posts={posts}
    />
  );
};

export default PostContainer;
