import { RootState } from '@store/rootReducer';
import { updateUser } from '@store/user/actions';
import { UserDataTypes } from '@typedef/components/Login/login.types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useUser() {
  const user = useSelector((root: RootState) => root.userReducer.user);

  const dispatch = useDispatch();

  const __updateUserFromHooks = useCallback(
    (diff: Partial<UserDataTypes>) => dispatch(updateUser(diff)),
    [dispatch],
  );

  return {
    user,
    __updateUserFromHooks,
  };
}
