import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import {
  apiRoute,
  requestSecureDelete,
  requestSecurePatch,
  requestSecurePost,
} from '@lib/api';
import {
  CardItemTypes,
  SectionItemTypes,
} from '@typedef/components/CalcItem/calc.item.types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SectionItem from '../components/SectionItem';

type Props = {
  data: SectionItemTypes;
  onSectionDeleted: (item: SectionItemTypes) => void;
};

const SectionItemContainer = ({ data, onSectionDeleted }: Props) => {
  const { getAccessToken } = useToken();

  const [sectionData, setSectionData] = useState(data);

  const [name, setName] = useState('');
  const [subName, setSubName] = useState('');
  const [price, setPrice] = useState('');

  const [checked, setChecked] = useState(data.isRequired === 1);

  const nameInpuRef = useRef<HTMLInputElement>(null);
  const subNameInpuRef = useRef<HTMLInputElement>(null);
  const priceInpuRef = useRef<HTMLInputElement>(null);

  const onDeleteBtnClicked = useCallback(async () => {
    const res = window.confirm(data.name + ' 을 삭제하시겠습니까?');

    if (!res) {
      return;
    }

    const { data: reponseData, config } =
      await requestSecureDelete<SectionItemTypes>(
        apiRoute.calcItem.deleteSection + '/' + data.id,
        {},
        getAccessToken(),
      );

    if (config.status === 202) {
      onSectionDeleted(reponseData);
    } else {
      alertMessage(data.name + ' 삭제에 실패 했습니다');
    }
  }, [data, getAccessToken, onSectionDeleted]);

  const onAddBtnClicked = useCallback(async () => {
    const { data, config } = await requestSecurePost<CardItemTypes>(
      apiRoute.calcItem.addCalcItem,
      {},
      {
        inquiryEstimateSectionId: sectionData.id,
        name,
        price,
        subName,
      },
      getAccessToken(),
    );

    if (config.status === 201) {
      setSectionData((prev) => ({
        ...prev,
        inquiryEstimateCategories: [...prev.inquiryEstimateCategories, data],
      }));

      setName('');
      setSubName('');
      setPrice('');

      if (nameInpuRef?.current) {
        nameInpuRef.current.value = '';
      }

      if (subNameInpuRef?.current) {
        subNameInpuRef.current.value = '';
      }

      if (priceInpuRef?.current) {
        priceInpuRef.current.value = '';
      }
    } else {
      alertMessage('추가에 실패 했습니다');
    }
  }, [
    sectionData,
    name,
    price,
    subName,
    nameInpuRef,
    subNameInpuRef,
    priceInpuRef,
    getAccessToken,
  ]);

  const onCheckChanged = useCallback(async () => {
    const { data, config } = await requestSecurePatch<SectionItemTypes>(
      apiRoute.calcItem.updateSection + '/' + sectionData.id,
      {},
      {
        isRequired: checked ? 1 : 0,
      },
      getAccessToken(),
    );

    if (config.status === 202) {
    } else {
      alertMessage('변경애 실패했습니다');
    }
  }, [sectionData, checked]);

  useEffect(() => {
    onCheckChanged();
  }, [onCheckChanged]);

  return (
    <SectionItem
      data={sectionData}
      checked={checked}
      setChecked={setChecked}
      onDeleteBtnClicked={onDeleteBtnClicked}
      setName={setName}
      setSubName={setSubName}
      setPrice={setPrice}
      onAddBtnClicked={onAddBtnClicked}
      nameInpuRef={nameInpuRef}
      subNameInpuRef={subNameInpuRef}
      priceInpuRef={priceInpuRef}
    />
  );
};

export default SectionItemContainer;
