import { PostTypes } from '@typedef/components/CardNews/card.news.types';
import React, { RefObject } from 'react';
import '../styles/main.news.card.styles.css';

type Props = {
  subTitleShow: boolean;
  cardRootRef: RefObject<HTMLDivElement>;
  post?: PostTypes;
  onClick?: () => void;
};

const MainNewsCard = ({ subTitleShow, cardRootRef, post, onClick }: Props) => {
  return (
    <div ref={cardRootRef} className='card-news-root' onClick={onClick}>
      <div className='content'>
        <span className='title'>
          {post ? post.title : '등록된 글이 없습니다'}
        </span>
        {subTitleShow && (
          <span className='sub-title'>
            {post ? post.subTitle : '등록된 글이 없습니다'}
          </span>
        )}
        <div className='tag'>
          {post && <span>#{post.postCategory.name}</span>}
        </div>
      </div>
      <div className='hover-indicator'>글을 변경하려면 클릭해주세요</div>
    </div>
  );
};

export default MainNewsCard;
