import { GroupItemTypes } from '@typedef/components/Group/group.types';
import {
  ADD_GROUP,
  CLEAR_GROUP_LIST,
  UPDATE_GROUP_LIST,
} from './modules/actionTypes';

export const updateGroupList = (list: GroupItemTypes[]) => ({
  type: UPDATE_GROUP_LIST,
  payload: list,
});

export const clearGroupList = () => ({
  type: CLEAR_GROUP_LIST,
  payload: [],
});

export const addGroup = (item: GroupItemTypes) => ({
  type: ADD_GROUP,
  payload: item,
});

export type GroupListActionTypes =
  | ReturnType<typeof updateGroupList>
  | ReturnType<typeof clearGroupList>
  | ReturnType<typeof addGroup>;
