import { PostTypes } from '@typedef/components/CardNews/card.news.types';
import React from 'react';
import '../styles/search.post.card.styles.css';

type Props = {
  onClick: () => void;
  data: PostTypes;
};

const SearchPostCard = ({ onClick, data }: Props) => {
  return (
    <div
      className='search-post-card-root'
      onClick={onClick}
      style={{
        background: `url(${data.postFile[0].location}) no-repeat`,
        backgroundSize: 'cover',
      }}>
      <div className='cover-section'>
        <div className='tag'>{data.postCategory.name}</div>
        <div className='title-article'>
          <div className='title'>{data.title}</div>
          <div className='sub-title'>{data.subTitle}</div>
        </div>
      </div>
    </div>
  );
};

export default SearchPostCard;
