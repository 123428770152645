import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GNB from '../GNB';

const GNBContainer = () => {
  const navigate = useNavigate();

  const [tab, setTab] = useState<string>('mainbanner');

  const naviateTab = useCallback(
    (selectedTab: string) => {
      navigate(`/${selectedTab}`);
    },
    [navigate],
  );

  const onTabClicked = useCallback(
    (selectedTab: string) => {
      setTab(selectedTab);
      naviateTab(selectedTab);
    },
    [naviateTab],
  );

  return (
    <GNB
      tab={tab}
      onTabClicked={onTabClicked}
      menuList={[
        'mainbanner',
        'cardnews',
        'post',
        'calcitem',
        'request',
        'apply',
        'qna',
        'admin',
        'group',
        'menu',
        'commoncode',
        'program',
      ]}
    />
  );
};

export default GNBContainer;
