import usePopup from '@hooks/popup/usePopup';
import { QnaType } from '@typedef/components/Qna/qna.types';
import React, { useCallback, useEffect, useState } from 'react';
import QnaCardItem from '../components/QnaCardItem';
import QnaDetailPopupContainer from './QnaDetailPopupContainer';

type Props = {
  item: QnaType;
};

const QnaCardItemContainer = ({ item }: Props) => {
  const { __showPopupFromHooks } = usePopup();
  const onCardItemClicked = useCallback(() => {
    __showPopupFromHooks(<QnaDetailPopupContainer item={item} />);
  }, [__showPopupFromHooks, item]);

  return <QnaCardItem item={item} onCardItemClicked={onCardItemClicked} />;
};

export default QnaCardItemContainer;
