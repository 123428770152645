import useRootRoute from '@hooks/routes/useRootRoute';
import React from 'react';
import RootNavigation from '../RootNavigation';

const RootNavigationContainer = () => {
  const { root } = useRootRoute();

  return <RootNavigation root={root} />;
};

export default RootNavigationContainer;
