import { GroupItemTypes } from '@typedef/components/Group/group.types';
import React from 'react';
import './styles/group.styles.css';

type Props = {
  groupList: GroupItemTypes[];
  onListItemClicked: (index: number) => void;
  onAddBtnClicked: () => void;
};

const Group = ({ groupList, onListItemClicked, onAddBtnClicked }: Props) => {
  return (
    <div className='group-root'>
      <div className='header-section'>
        <div className='title'>권한그룹 관리</div>
        <button onClick={onAddBtnClicked} className='add-btn'>
          추가하기
        </button>
      </div>
      <div className='content-section'>
        <div className='list-section'>
          <div className='header-article'>
            <span className='header-item'>권한 그룹 UID</span>
            <span className='header-item'>권한 그룹 이름</span>
            <span className='header-item'>접근 가능 메뉴</span>
          </div>
          <div className='body-article'>
            {groupList.map((item, index) => (
              <div
                onClick={() => onListItemClicked(index)}
                key={index}
                className='list-item'>
                <span className='item-label'>{item.code}</span>
                <span className='item-label'>{item.name}</span>
                <span className='item-label'>
                  {item.menus.map((item) => item.name).join(', ')}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Group;
