import { ProgramTypes } from '@typedef/components/Program/program.types';
import {
  ADD_PROGRAM,
  CLEAR_PROGRAM_LIST,
  UPDATE_PROGRAM_LIST,
} from './modules/actionTypes';

export const updateProgramList = (list: ProgramTypes[]) => ({
  type: UPDATE_PROGRAM_LIST,
  payload: list,
});

export const clearProgramList = () => ({
  type: CLEAR_PROGRAM_LIST,
  payload: [],
});

export const addProgram = (item: ProgramTypes) => ({
  type: ADD_PROGRAM,
  payload: item,
});

export type ProgramListActionTypes =
  | ReturnType<typeof updateProgramList>
  | ReturnType<typeof clearProgramList>
  | ReturnType<typeof addProgram>;
