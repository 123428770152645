import React from 'react';
import '../styles/portfolio.card.styles.css';

const PortfolioCard = () => {
  return (
    <div className='card-root'>
      <span className='title'>Welcome to the WIM!</span>
      <span className='sub-title'>포트폴리오 보러가기</span>
      <span className='ai-button'>AI portfolio</span>
      <span className='web-app-button'>Web/App portfolio</span>
    </div>
  );
};

export default PortfolioCard;
