import useToken from '@hooks/useToken';
import { apiRoute, requestSecureGet } from '@lib/api';
import { AdminType } from '@typedef/components/Admin/admin.types';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Admin from '../Admin';

const listdummy = [
  {
    createdAt: '2022-04-11T09:09:00.309Z',
    updatedAt: '2022-04-11T10:36:17.000Z',
    deletedAt: null,
    id: '33fd79d6-297f-44ce-b023-4dd578e95691',
    userName: 'test',
    displayName: 'test',
    email: 'hbjs97@naver.com',
    phone: '01080100275',
    profileUrl:
      'https://jest-test.s3.ap-northeast-2.amazonaws.com/images/d042f575-c1c6-463e-8437-e8865e25d8b9.jpeg',
  },
];

const AdminContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const [adminList, setAdminList] = useState<AdminType[]>([]);
  const [search, setSearch] = useState('');

  const getAdminList = useCallback(async () => {
    const accessToken = getAccessToken();
    const {
      data,
      config: { status },
    } = await requestSecureGet<{ data: AdminType[] }>(
      apiRoute.admin.getAdmins +
        `?order=ASC&orderColumnName=createdAt&page=1&take=50`,
      {},
      accessToken,
    );

    if (status !== 200) {
      console.error('getAdminList Failed');
      return;
    }
    setAdminList(data.data);
  }, [getAccessToken]);

  const onSearch = useCallback(
    async (keyword: string) => {
      const accessToken = getAccessToken();
      const {
        data,
        config: { status },
      } = await requestSecureGet<{ data: AdminType[] }>(
        apiRoute.admin.getAdmins +
          `?order=ASC&orderColumnName=createdAt&page=1&take=50&qColumnNames=userName&q=${keyword}`,
        {},
        accessToken,
      );

      if (status !== 200) {
        console.error('getAdminList Failed');
        return;
      }
      setAdminList(data.data);
    },
    [getAccessToken],
  );

  const onCreateClicked = useCallback(() => {
    console.log('click');
    navigate('./create');
  }, [navigate]);

  useEffect(() => {
    getAdminList();
  }, []);

  useEffect(() => {
    console.log(adminList);
  }, [adminList]);

  return (
    <Admin
      list={adminList}
      onSearch={onSearch}
      onCreateClicked={onCreateClicked}
    />
  );
};

export default AdminContainer;
