import useMenu from '@hooks/menu/useMenu';
import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import {
  apiRoute,
  BasicListApiResponse,
  requestSecureGet,
  requestSecurePost,
} from '@lib/api';
import { GroupItemTypes } from '@typedef/components/Group/group.types';
import { MenuItemType } from '@typedef/components/Menu/menu.types';
import { ProgramTypes } from '@typedef/components/Program/program.types';
import React, { useCallback, useEffect, useState } from 'react';
import MenuAddPopup from '../components/MenuAddPopup';

const MenuAddPopupContainer = () => {
  const { getAccessToken } = useToken();
  const { __hidePopupFromHooks } = usePopup();
  const { __addMenuListFromHooks } = useMenu();

  const [programs, setPrograms] = useState<ProgramTypes[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<ProgramTypes | null>(
    null,
  );
  const [groups, setGroups] = useState<GroupItemTypes[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupItemTypes | null>(
    null,
  );
  const [menuName, setMenuName] = useState('');

  const loadPrograms = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<ProgramTypes[]>
    >(apiRoute.program.getProgramList, {}, getAccessToken());

    if (config.status === 200) {
      setPrograms(data.data);
    } else {
      alertMessage('목록을 불러오는데 실패 했습니다');
    }
  }, [getAccessToken]);

  const loadGroups = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<GroupItemTypes[]>
    >(apiRoute.group.getGroupList, {}, getAccessToken());

    if (config.status === 200) {
      setGroups(data.data);
    } else {
      alertMessage('목록을 불러오는데 실패했습니다');
    }
  }, [getAccessToken]);

  const onCancelBtnClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, []);

  const onAddBtnClicked = useCallback(async () => {
    const { data, config } = await requestSecurePost<MenuItemType>(
      apiRoute.menu.addMenu,
      {},
      {
        programCode: selectedProgram?.code,
        authGroupCode: selectedGroup?.code,
        name: menuName,
        sort: 0,
      },
      getAccessToken(),
    );

    if (config.status === 201) {
      __addMenuListFromHooks(data);
    } else {
      alert('매뉴 등록에 실패했습니다 ');
    }
  }, [
    selectedProgram,
    selectedGroup,
    menuName,
    getAccessToken,
    __addMenuListFromHooks,
  ]);

  useEffect(() => {
    loadPrograms();
    loadGroups();
  }, []);

  return (
    <MenuAddPopup
      programs={programs}
      setSelectedProgram={setSelectedProgram}
      groups={groups}
      setSelectedGroup={setSelectedGroup}
      setMenuName={setMenuName}
      onCancelBtnClicked={onCancelBtnClicked}
      onAddBtnClicked={onAddBtnClicked}
    />
  );
};

export default MenuAddPopupContainer;
