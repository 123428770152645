import React from 'react';
import { PopupActionTypes } from './actions';
import { HIDE_POPUP, SHOW_POPUP } from './modules/actionTypes';

export type PopupStateTypes = {
  visibility: boolean;
  child: React.ReactNode;
};

const initialState: PopupStateTypes = {
  visibility: false,
  child: null,
};

const popupReducer = (
  prev: PopupStateTypes = initialState,
  { type, payload }: PopupActionTypes,
): PopupStateTypes => {
  switch (type) {
    case SHOW_POPUP:
      return {
        visibility: true,
        child: payload,
      };
    case HIDE_POPUP:
      return initialState;
    default:
      return prev;
  }
};

export default popupReducer;
