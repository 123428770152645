import { combineReducers } from 'redux';
import rootRouteReducer from './routes/rootRoute/reducers';
import userReducer from './user/reducer';
import popupReducer from './popup/reducer';
import qnaReducer from './qna/reducers';
import programListReducer from './programs/reducer';
import menuListReducer from './menu/reducer';
import groupListReducer from './group/reducer';

const rootReducer = combineReducers({
  rootRouteReducer,
  userReducer,
  popupReducer,
  qnaReducer,
  programListReducer,
  menuListReducer,
  groupListReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
