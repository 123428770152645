import React from 'react';
import '../styles/qna.create.popup.style.css';

type Props = {
  onCancelClicked: () => void;
  onStoreClicked: () => void;
  onChangeHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => void;
};

const QnaCreatePopup = ({
  onCancelClicked,
  onStoreClicked,
  onChangeHandler,
}: Props) => {
  return (
    <div className='qna-create-root' onClick={(e) => e.stopPropagation()}>
      <div className='title-section'>
        <div className='section-label'>제목</div>
        <input
          className='title-content'
          onChange={(e) => onChangeHandler(e, 'title')}
        />
      </div>
      <div className='content-section'>
        <div className='section-label'>내용</div>
        <input
          className='content-input'
          onChange={(e) => onChangeHandler(e, 'contents')}
        />
      </div>
      <div className='button-wrapper'>
        <button onClick={onCancelClicked}>취소하기</button>
        <button onClick={onStoreClicked}>저장하기</button>
      </div>
    </div>
  );
};

export default QnaCreatePopup;
