import useMenu from '@hooks/menu/useMenu';
import usePopup from '@hooks/popup/usePopup';
import useToken from '@hooks/useToken';
import alertMessage from '@lib/alert';
import { apiRoute, BasicListApiResponse, requestSecureGet } from '@lib/api';
import { MenuItemType } from '@typedef/components/Menu/menu.types';
import React, { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu';
import MenuAddPopupContainer from './MenuAddPopupContainer';

const MenuContainer = () => {
  const { menu, __updateMenuFromHooks, __clearMenuListFromHooks } = useMenu();

  const { __showPopupFromHooks } = usePopup();

  const { getAccessToken } = useToken();

  const loadMenuList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<MenuItemType[]>
    >(apiRoute.menu.getMenuList, {}, getAccessToken());

    if (config.status === 200) {
      __updateMenuFromHooks(data.data);
    } else {
      alertMessage('목록을 불러오는데 실패했습니다');
    }
  }, [getAccessToken]);

  const onAddBtnClicked = useCallback(() => {
    __showPopupFromHooks(<MenuAddPopupContainer />);
  }, [__showPopupFromHooks]);

  useEffect(() => {
    loadMenuList();

    return () => {
      __clearMenuListFromHooks();
    };
  }, []);

  return <Menu menu={menu} onAddBtnClicked={onAddBtnClicked} />;
};

export default MenuContainer;
